import './App.css';
import Routing from './Routing'
import Loading from './customComponents/Loading';
import LoaderHelper from './customComponents/Loading/LoaderHelper';
import { ProfileProvider } from './context/ProfileProvider';
import { ToastContainer } from 'react-toastify';
import SocketContextProvider from './customComponents/SocketContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  return (

    <GoogleOAuthProvider clientId="135366155117-hvq8bq2mlatief4rg065k8aublhcp9j7.apps.googleusercontent.com">

      <ProfileProvider>
        <SocketContextProvider>
          <Routing />
          <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        </SocketContextProvider>
        <ToastContainer />
      </ProfileProvider>

    </GoogleOAuthProvider>


  )
};

export default App;
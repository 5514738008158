import React, { useState, useEffect, useMemo, useContext } from "react";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import { notEqualsZero, aadharNum, email, passport, validateNumber, matchCard } from "../../../utils/Validation";
import AuthService from "../../../api/services/AuthService";
import countryList from 'react-select-country-list';
import DefaultInput from "../../../customComponents/DefaultInput";
import { validatePanCard, matchPanCard, drivingLicense, documentNum } from "../../../utils/Validation";
import moment from "moment";
import { Link } from "react-router-dom";
import KycButton from "../../../customComponents/KycButton";
import { ProfileContext } from "../../../context/ProfileProvider";


const KycPage = (props) => {

    const { handleUserDetails } = useContext(ProfileContext);

    const options = useMemo(() => countryList().getData(), []);
    const [kycVerfied, setKycVerified] = useState("");
    const [infoCountry, setInfoCountry] = useState("India");
    const [kycType, setKycType] = useState("Personal");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState(" ");
    const [lastName, setLastName] = useState("");
    const [infoDob, setInfoDob] = useState("");
    const [docType, setDocType] = useState("Aadhaar");
    const [address, setAddress] = useState("");
    const [infoState, setInfoState] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [localFront, setLocalFront] = useState("");
    const [localBack, setLocalBack] = useState("");
    const [localSelfie, setLocalSelfie] = useState("");
    const [panCard, setPanCard] = useState("");
    const [rePanCard, setRePanCard] = useState("");
    const [localPanCard, setLocalPanCard] = useState("");
    const [kyc2fa, setKyc2fa] = useState("");
    const [emailId, setEmailId] = useState("");
    const [gender, setGender] = useState("male");
    const [reason, setReason] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileOtp, setMobileOtp] = useState("");

    const [newEmail, setNewEmail] = useState("");
    const [newMobile, setNewMobile] = useState("");

    const [disableBtn2, setDisbaleBtn2] = useState(false);
    const [disableBtn, setDisbaleBtn] = useState(false);
    const [emailOtp, setemailOtp] = useState();
    const [isShow, setIsShow] = useState(1);

    useEffect(() => {
        setKycVerified(props?.userDetails?.kycVerified);
        setKyc2fa(props?.userDetails?.["2fa"]);
        setEmailId(props?.userDetails?.emailId);
        setMobileNumber(props?.userDetails?.mobileNumber);
        setNewMobile(props?.userDetails?.mobileNumber);
        setNewEmail(props?.userDetails?.emailId);
        setFirstName(props?.userDetails?.firstName);
        setLastName(props?.userDetails?.lastName);
        setReason(props?.userDetails?.kyc_reject_reason);
    }, [props]);

    const handleChangeIdentity = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                const imgData = URL.createObjectURL(file);
                setLocalFront(file);
                alertSuccessMessage(file?.name)
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
                } else {
                    alertErrorMessage("Max image size is 2MB.");
                }
            }
        }
    };


    const handleChangeIdentity2 = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                const imgData = URL.createObjectURL(file);
                setLocalBack(file);
                alertSuccessMessage(file?.name)
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
                } else {
                    alertErrorMessage("Max image size is 2MB.");
                }
            }
        }
    };


    const handleChangeSelfie = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                const imgData = URL.createObjectURL(file);
                setLocalSelfie(file);
                alertSuccessMessage(file?.name)
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
                } else {
                    alertErrorMessage("Max image size is 2MB.");
                }
            }
        }
    };

    const handleChangePanCard = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                const imgData = URL.createObjectURL(file);
                setLocalPanCard(file);
                alertSuccessMessage(file?.name)
            } else {
                if (!allowedTypes.includes(file.type)) {
                    alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
                } else {
                    alertErrorMessage("Max image size is 2MB.");
                }
            }
        }
    };


    const handleKyc = async (infoCountry, kycType, firstName, lastName, gender, infoDob, address, infoState, city, zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, emailOtp, emailId, mobileNumber, mobileOtp) => {
        var formData = new FormData();
        formData.append("document_front_image", localFront);
        formData.append("document_back_image", localBack);
        formData.append("user_selfie", localSelfie);
        formData.append("pancard_image", localPanCard);
        formData.append("address", address);
        formData.append("city", city);
        formData.append("state", infoState);
        formData.append("country", infoCountry);
        formData.append("document_number", aadhar);
        formData.append("pancard_number", panCard);
        formData.append("confirm_pancard_number", rePanCard);
        formData.append("dob", infoDob);
        formData.append("zip_code", zipCode);
        formData.append("first_name", firstName);
        formData.append("middle_name", middleName);
        formData.append("last_name", lastName);
        formData.append("kyc_type", kycType);
        formData.append("gender", gender);
        formData.append("document_type", docType);
        formData.append("eotp", emailOtp);
        formData.append("emailId", emailId);
        formData.append("mobileNumber", mobileNumber);
        formData.append("motp", mobileOtp);
        // formData.append("ton_address", tonAddress);
        LoaderHelper.loaderStatus(true);
        await AuthService.addkyc(formData).then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message);
                    handleResetInput();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    handleUserDetails();
                } catch (error) {
                    alertErrorMessage(result?.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    const handleResetInput = () => {
        setInfoCountry("");
        setMiddleName("");
        setFirstName("");
        setLastName("");
        setInfoDob("");
        setAddress("");
        setInfoState("");
        setCity("");
        setZipCode("");
        setAadhar("");
        setLocalFront("");
        setLocalBack("");
        setLocalSelfie("");
        setPanCard("");
        setRePanCard("");
    };

    const handleSelected = (type) => {
        setIsShow(type);
    };

    const getEighteenYearsAgoDate = () => {
        let eighteenYearsAgo = new Date();
        eighteenYearsAgo = eighteenYearsAgo.setFullYear(
            eighteenYearsAgo.getFullYear() - 18
        );
        eighteenYearsAgo = moment(eighteenYearsAgo).format("YYYY-MM-DD");
        return eighteenYearsAgo;
    };

    const verifyAgain = () => {
        setKycVerified("4");
    };

    const handleGetOtp = async (signId, type) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(signId, type).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    return (
        <>

            <div className="profile_sections" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-6" >
                            <h2 className="mb-0 pb-0"> KYC Verification </h2>
                        </div>
                        <div className="col-md-6" >
                            <nav className=" ">
                                <ol className="breadcrumb justify-content-end mb-0 ">
                                    <li className="breadcrumb-item"><Link to="/user_profile">My Account</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"> KYC Verification </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pb-5">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto ">
                            {
                                kycVerfied == "1" ?
                                    // pending KYC
                                    <div className="card mb-5" >
                                        <div className="card-body create-item-wrapper create-item-wrapper-kyc">
                                            <div className="form-field-wrapper kyc_wrapper ">
                                                <div className="rightsidebox">
                                                    <div className="kyc_nofti kyc_done" >
                                                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                                                            <div className={`check_bar ${!emailId ? "" : "active"}`}><i className="ri-check-fill"></i>
                                                                <h5> Email</h5>
                                                            </div>
                                                            <div className={`check_bar ${kyc2fa == '0' ? "" : "active"}`}><i className="ri-check-fill"></i>
                                                                <h5> Security </h5>
                                                            </div>
                                                            <div className="check_bar "><i className="ri-check-fill"></i>
                                                                <h5> Welcome </h5>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="d-kyc_sec" >
                                                            <div>
                                                                <h4 className="text-success"> <strong>KYC Pending</strong></h4>
                                                                <p>Your CV Trade account is pending for Verification</p>
                                                            </div>
                                                            <Link to="/user_profile" className="btn custom-btn custom-border-btn  custom-border-btn-white me-2">
                                                                <span> Back to Home </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : kycVerfied == "2" ?
                                        //  Verify Approved
                                        <div className="container" >
                                            <div className="row" >
                                                <div className="col-lg-10 m-auto" >
                                                    <div className="create-item-wrapper create-item-wrapper-kyc">
                                                        <div className="form-field-wrapper kyc_wrapper ">
                                                            <div className="rightsidebox">
                                                                <div className="kyc_nofti kyc_done" >
                                                                    <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                                                                        <div className={`check_bar ${!emailId ? "" : "active"}`}><i className="ri-check-fill " value={email === "undefined" ? "" : email}></i>
                                                                            <h5> Email</h5>
                                                                        </div>
                                                                        <div className={`check_bar ${!kyc2fa ? "" : "active"}`}><i className="ri-check-fill "></i>
                                                                            <h5> Security </h5>
                                                                        </div>
                                                                        <div className="check_bar active"><i className="ri-check-fill "></i>
                                                                            <h5> Welcome </h5>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="d-kyc_sec" >
                                                                        <div>
                                                                            <h4 className="text-success pb-0">Congratulations</h4>
                                                                            <p>Your CV Trade account Kyc is Approved</p>
                                                                        </div>
                                                                        <Link to="/trade/KYC" className="btn custom-btn justify-content-center "> <span>Start Trading</span> </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : kycVerfied == "3" ?
                                            // KYC Rejected
                                            <div className="container" >
                                                <div className="row" >
                                                    <div className="col-lg-10 m-auto" >
                                                        <div className="create-item-wrapper create-item-wrapper-kyc">
                                                            <div className="form-field-wrapper kyc_wrapper ">
                                                                <div className="rightsidebox">
                                                                    <div className="kyc_nofti kyc_done" >
                                                                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                                                                            <div className={`check_bar ${!emailId ? "" : "active"}`}><i className="ri-check-fill"></i>
                                                                                <h5> Email</h5>
                                                                            </div>
                                                                            <div className={`check_bar ${!kyc2fa ? "" : "active"}`}><i className="ri-check-fill"></i>
                                                                                <h5> Security </h5>
                                                                            </div>
                                                                            <div className="check_bar "><i className="ri-check-fill"></i>
                                                                                <h5> Welcome </h5>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="d-kyc_sec" >
                                                                            <div>
                                                                                <h5>Your CV Trade account Kyc is Rejected</h5>
                                                                                <span className="text-danger mt-3">{reason}</span>
                                                                            </div>
                                                                            <button className="btn custom-btn justify-content-center btn-danger" onClick={verifyAgain}><span>Verify Again</span></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            // KYC Rejected Form 
                                            <div className="kyc_col" >

                                                <form className="card no-shadow"  >
                                                    <div className="card-body create-item-wrapper create-item-wrapper-kyc custom-form ">
                                                        <div className="mb-3 pt-6">
                                                            <h5 className="title ">
                                                                Region or KYC Type <span className="text-danger" >*</span>
                                                            </h5>
                                                        </div>
                                                        <div className="form-field-wrapper mb-4 ">
                                                            <div className="row">
                                                                <div className="col-md-6  mb-3 ">
                                                                    <div className="field-box">
                                                                        <label htmlFor="royality" className="form-label">Select country</label>
                                                                        <select id="royality" className="form-control form-select" value={infoCountry} name="infoCountry" onChange={(event) => setInfoCountry(event.target.value)}>
                                                                            <option defaultValue>India</option>
                                                                            {options.map((item, index) =>
                                                                                <option value={item.label} key={index}>{item.label}</option>,
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6   mb-3">
                                                                    <div className="field-box">
                                                                        <label htmlFor="sizes" className="form-label ">KYC Type</label>
                                                                        <input type="text" className="form-control" value={kycType} name="kycType" disabled>

                                                                        </input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 pt-6">
                                                            <h5 className="title ">
                                                                Personal Information <span className="text-danger" >*</span>
                                                            </h5>
                                                        </div>
                                                        <div className="form-field-wrapper mb-4 ">

                                                            <div className="row">
                                                                <div className="col-lg-5 mb-4">
                                                                    <div className="mt-4 mb-3">
                                                                        <h6 className="title mb-1">
                                                                            Upload Your Image(Selfie)
                                                                        </h6>
                                                                        <div className=" text-smallest mb-1" >
                                                                            <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="form-field-wrapper "> */}
                                                                    <div className="">
                                                                        <div className=" upload-area">
                                                                            <div className="brows-file-wrapper">
                                                                                <input name="file" id="file1" type="file" className="inputfile"
                                                                                    data-multiple-caption="{count} files selected" onChange={handleChangeSelfie} required />
                                                                                {localSelfie === "" ?
                                                                                    <label htmlFor="file1" title="No File Choosen">
                                                                                        <i className="ri-upload-cloud-line"></i>
                                                                                        <span className="text-center mb-2">Choose a File</span>
                                                                                        < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                    </label>
                                                                                    : <label htmlFor="file1" title="No File Choosen">
                                                                                        <i className=" text-success ri-check-double-fill"></i>
                                                                                        <span className="text-center mb-2">File Uploaded</span>
                                                                                        <span className="file-type text-center mt--10" >{localSelfie?.name}</span>
                                                                                    </label>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7">
                                                                    <div className="field-box mb-3">
                                                                        <label htmlFor="name" className="form-label">First Name</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" name="firstName" value={firstName === "undefined" ? "" : firstName} onChange={(event) => setFirstName(event.target.value)} required />
                                                                    </div>

                                                                    <div className="field-box mb-3">
                                                                        <label htmlFor="name" className="form-label">Last Name</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" name="lastName" value={lastName === "undefined" ? "" : lastName} onChange={(event) => setLastName(event.target.value)} required />
                                                                    </div>

                                                                    <div className="field-box mb-3">
                                                                        <label htmlFor="name" className="form-label">Date of birth</label>
                                                                        <input id="name" type="date" placeholder="" className="form-control" max={getEighteenYearsAgoDate()} value={infoDob} name="infoDob" onChange={(event) => setInfoDob(event.target.value)} required />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="pb-2" htmlFor="">
                                                                            Gender
                                                                        </label>
                                                                        <div className="d-flex align-items-center mb-3">
                                                                            <div className="form-check me-5">
                                                                                <input
                                                                                    id="male"
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="gender"
                                                                                    value="male"
                                                                                    onClick={(event) => {
                                                                                        if (event.target.value !== gender) {
                                                                                            setGender(event.target.value);
                                                                                        }
                                                                                    }}
                                                                                    checked={gender === "male"}
                                                                                />
                                                                                <label className="form-check-label ms-2" htmlhtmlFor="male">
                                                                                    Male
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    id="female"
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    value="female"
                                                                                    name="gender"
                                                                                    onClick={(event) => {
                                                                                        if (event.target.value !== gender) {
                                                                                            setGender(event.target.value);
                                                                                        }
                                                                                    }}
                                                                                    checked={gender === "female"}
                                                                                />
                                                                                <label className="form-check-label ms-2" htmlhtmlFor="female">
                                                                                    Female
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <hr />
                                                                </div>



                                                                {!newMobile ? (
                                                                    <>
                                                                        <div className="col-8">
                                                                            <div className="fleld-box">
                                                                                <label htmlFor="email" className="form-label">
                                                                                    Mobile Number
                                                                                    <span style={{ color: "red" }}>*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 mb-4">
                                                                            <div className="field-box field-otp-box">
                                                                                <DefaultInput required className="form-control" type="text" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />

                                                                                {/* <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(mobileNumber, "kyc"); setDisbaleBtn(true); }} >
                                                                                    <span> {disableBtn ? 'Resend OTP' : 'GET OTP '}</span>
                                                                                </button> */}

                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="col-md-4 mb-3">
                                                                            <div className="field-box">
                                                                                <input className="form-control" id="mobileNumber" type="text" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={(e) => { setMobileOtp(e.target.value) }} />
                                                                            </div>
                                                                        </div> */}
                                                                    </>



                                                                ) : !newEmail ? (

                                                                    <>
                                                                        <div className="col-8">
                                                                            <div className="fleld-box">
                                                                                <label htmlFor="email" className="form-label">
                                                                                    Email Id
                                                                                    <span style={{ color: "red" }}>*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8 mb-4">
                                                                            <div className="field-box field-otp-box">
                                                                                <DefaultInput required errorstatus={email(emailId)} errormessage={email(emailId)} className="form-control" type="email" placeholder="Enter Email Id" value={emailId} onChange={(e) => setEmailId(e.target.value)} />

                                                                                <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(emailId, "kyc"); setDisbaleBtn2(true); }} >
                                                                                    <span> {disableBtn2 ? 'Resend OTP' : 'GET OTP '}</span>
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4 mb-3">
                                                                            <div className="field-box">
                                                                                <input className="form-control" id="emailOTP" type="text" placeholder="Enter Email OTP" value={emailOtp} onChange={(e) => { setemailOtp(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : null}


                                                                <div className="col-12" >
                                                                    <h6 className="acc_title mt-2">Address Information </h6>
                                                                </div>
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="name" className="form-label">Pin Code</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" value={zipCode} name="city" onChange={(event) => setZipCode(event.target.value)} required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="name" className="form-label">City</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" value={city} name="city" onChange={(e) => setCity(e.target.value)} required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="name" className="form-label">State</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" value={infoState} name="State" onChange={(e) => setInfoState(e.target.value)} required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="name" className="form-label">Address</label>
                                                                        <input id="name" type="text" placeholder="" className="form-control" value={address} name="address" onChange={(event) => setAddress(event.target.value)} required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {infoCountry !== "India" ? <>
                                                            <div className="mb-3 pt-6">
                                                                <h5 className="title ">
                                                                    Document Verification <small className="text-muted" >(Driving Licence)</small>  <span className="text-danger" >*</span>
                                                                </h5>
                                                            </div>
                                                            <div className="form-field-wrapper mb-4">
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-4">
                                                                        <div className="field-box">
                                                                            <label htmlFor="name" className="form-label">Driving Licence Number</label>
                                                                            <DefaultInput id="name" type="text" placeholder="" className="form-control" value={panCard} errorstatus={ notEqualsZero(panCard)} errormessage={ notEqualsZero(panCard)} onChange={(event) => setPanCard(event.target.value.toUpperCase())} required />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mb-4">
                                                                        <div className="field-box">
                                                                            <label htmlFor="name" className="form-label"> Confirm Driving Licence Number</label>
                                                                            <DefaultInput id="name" type="text" placeholder="" className="form-control" value={rePanCard} errorstatus={matchCard(panCard, rePanCard)} errormessage={ matchCard(panCard, rePanCard)} onChange={(event) => setRePanCard(event.target.value.toUpperCase())} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="upload-area" >
                                                                        <div className="upload-formate mb-3">
                                                                            <h6 className="title mb-1">
                                                                                Upload Item File
                                                                            </h6>
                                                                            <div className=" text-smallest mb-1" >
                                                                                <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="brows-file-wrapper mb-4">
                                                                            <input name="file" id="file2" type="file" className="inputfile"
                                                                                data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} required />

                                                                            {localPanCard === "" ?
                                                                                <label htmlFor="file2" title="No File Choosen">
                                                                                    <i className="ri-upload-cloud-line"></i>
                                                                                    <span className="text-center mb-2">Choose a File</span>
                                                                                    < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                </label>
                                                                                : <label htmlFor="file2" title="No File Choosen">
                                                                                    <i className=" text-success ri-check-double-fill"></i>
                                                                                    <span className="text-center mb-2">File Uploaded</span>
                                                                                    <span className="file-type text-center mt--10" >{localPanCard?.name}</span>
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> :
                                                            <>
                                                                <div className="mb-3 pt-6">
                                                                    <h5 className="title ">
                                                                        Document Verification <small className="text-muted" >(Pan Card)</small>  <span className="text-danger" >*</span>
                                                                    </h5>
                                                                </div>
                                                                <div className="form-field-wrapper mb-4">
                                                                    <div className="row">
                                                                        <div className="col-md-6 mb-4">
                                                                            <div className="field-box">
                                                                                <label htmlFor="name" className="form-label">Pan Card Number</label>
                                                                                <DefaultInput id="name" type="text" placeholder="" className="form-control" value={panCard} errorstatus={validatePanCard(panCard) !== undefined && notEqualsZero(panCard)} errormessage={validatePanCard(panCard)} onChange={(event) => setPanCard(event.target.value.toUpperCase())} required />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6 mb-4">
                                                                            <div className="field-box">
                                                                                <label htmlFor="name" className="form-label"> Confirm Pan Card Number</label>
                                                                                <DefaultInput id="name" type="text" placeholder="" className="form-control" value={rePanCard} errorstatus={validatePanCard(rePanCard) !== undefined && notEqualsZero(rePanCard) || matchPanCard(panCard, rePanCard)} errormessage={validatePanCard(rePanCard) || matchPanCard(panCard, rePanCard)} onChange={(event) => setRePanCard(event.target.value.toUpperCase())} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="upload-area" >
                                                                            <div className="upload-formate mb-3">
                                                                                <h6 className="title mb-1">
                                                                                    Upload Item File
                                                                                </h6>
                                                                                <div className=" text-smallest mb-1" >
                                                                                    <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="brows-file-wrapper mb-4">
                                                                                <input name="file" id="file2" type="file" className="inputfile"
                                                                                    data-multiple-caption="{count} files selected" multiple onChange={handleChangePanCard} required />

                                                                                {localPanCard === "" ?
                                                                                    <label htmlFor="file2" title="No File Choosen">
                                                                                        <i className="ri-upload-cloud-line"></i>
                                                                                        <span className="text-center mb-2">Choose a File</span>
                                                                                        < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                    </label>
                                                                                    : <label htmlFor="file2" title="No File Choosen">
                                                                                        <i className=" text-success ri-check-double-fill"></i>
                                                                                        <span className="text-center mb-2">File Uploaded</span>
                                                                                        <span className="file-type text-center mt--10" >{localPanCard?.name}</span>
                                                                                    </label>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {infoCountry !== "India" ? <> <div className="mb-3 pt-6">
                                                            <h5 className="title ">
                                                                Document Verification (Any National ID) <span className="text-danger" >*</span>
                                                            </h5>
                                                        </div>  <div className="form-field-wrapper mb-4 ">
                                                                <div className="row">
                                                                    <div className="col-md-6  mb-4">
                                                                        {/* <div className="field-box">
                                                                        <label htmlFor="sizes" className="form-label">Select Document Type</label>
                                                                        <select id="royality" className="form-control form-select" onChange={(event) => {
                                                                            setAadhar("");
                                                                            setDocType(event.target.value)
                                                                            handleSelected(event.target.value === "Aadhaar" ? 1 : event.target.value === "Driving License" ? 2 : event.target.value === "Other" ? 3 : event.target.value === 'Passport' ? 4 : undefined)
                                                                        }}  >
                                                                            <option defaultValue value="Aadhaar">Aadhar card </option>
                                                                            <option value="Driving License">Driving License</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                    </div> */}
                                                                    </div>
                                                                    <div className="col-md-12 mb-4">
                                                                        <div
                                                                            className="field-box"
                                                                        >
                                                                            <label htmlFor="name" className="form-label">
                                                                                Document Number
                                                                            </label>
                                                                            <DefaultInput id="name" type="text" className="form-control" required name="aadhar" value={aadhar} onChange={(event) => { setAadhar(event.target.value); handleSelected("3") }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 upload-area" >
                                                                        <div className="upload-formate mb-3">
                                                                            <h6 className="title mb-1">
                                                                                Front Image
                                                                            </h6>
                                                                            <div className=" text-smallest mb-1" >
                                                                                <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="brows-file-wrapper">
                                                                            <input name="file" id="file3" type="file" className="inputfile"
                                                                                data-multiple-caption="{count} files selected" onChange={handleChangeIdentity} required />
                                                                            {localFront === '' ?
                                                                                <label htmlFor="file3" title="No File Choosen">
                                                                                    <i className="ri-upload-cloud-line"></i>
                                                                                    <span className="text-center mb-2">Choose a File</span>
                                                                                    < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                </label>
                                                                                : <label htmlFor="file3" title="No File Choosen">
                                                                                    <i className=" text-success ri-check-double-fill"></i>
                                                                                    <span className="text-center mb-2">File Uploaded</span>
                                                                                    <span className="file-type text-center mt--10" >{localFront?.name}</span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 upload-area" >
                                                                        <div className="upload-formate mb-3">
                                                                            <h6 className="title mb-1">
                                                                                Back Image
                                                                            </h6>
                                                                            <div className=" text-smallest mb-1" >
                                                                                <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="brows-file-wrapper">
                                                                            <input name="file" id="file4" type="file" className="inputfile"
                                                                                data-multiple-caption="{count} files selected" onChange={handleChangeIdentity2} required />

                                                                            {localBack === '' ?
                                                                                <label htmlFor="file4" title="No File Choosen">
                                                                                    <i className="ri-upload-cloud-line"></i>
                                                                                    <span className="text-center mb-2">Choose a File</span>
                                                                                    < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                </label>
                                                                                : <label htmlFor="file4" title="No File Choosen">
                                                                                    <i className=" text-success ri-check-double-fill"></i>
                                                                                    <span className="text-center mb-2">File Uploaded</span>
                                                                                    <span className="file-type text-center mt--10" >{localBack?.name}</span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    {/* </div> */}
                                                                    <div className="" >
                                                                        <button type='button' className=" mt-4  btn w-100 btn-gradient btn-xl  justify-content-center" onClick={() => handleKyc(infoCountry, kycType, firstName, lastName, gender, infoDob, address, infoState, city, zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, emailOtp, emailId, mobileNumber, mobileOtp)}>
                                                                            <span>Submit for verification</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div> </> :
                                                            <>
                                                                <div className="mb-3 pt-6">
                                                                    <h5 className="title ">
                                                                        Other Document Verification <span className="text-danger" >*</span>
                                                                    </h5>
                                                                </div>
                                                                <div className="form-field-wrapper mb-4 ">
                                                                    <div className="row">
                                                                        <div className="col-md-6  mb-4">
                                                                            <div className="field-box">
                                                                                <label htmlFor="sizes" className="form-label">Select Document Type</label>
                                                                                <select id="royality" className="form-control form-select" onChange={(event) => {
                                                                                    setAadhar("");
                                                                                    setDocType(event.target.value)
                                                                                    handleSelected(event.target.value === "Aadhaar" ? 1 : event.target.value === "Driving License" ? 2 : event.target.value === "Other" ? 3 : event.target.value === 'Passport' ? 4 : undefined)
                                                                                }}  >
                                                                                    <option defaultValue value="Aadhaar">Aadhar card </option>
                                                                                    <option value="Driving License">Driving License</option>
                                                                                    <option value="Other">Other</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 mb-4">
                                                                            <div
                                                                                className={`field-box ${isShow !== 1 && "d-none"}`}
                                                                                id="aadhar" >
                                                                                <label htmlFor="name" className="form-label">
                                                                                    Aadhar Number
                                                                                    <span style={{ color: "red" }}>*</span>
                                                                                </label>
                                                                                <DefaultInput id="name" type="text" className="form-control"
                                                                                    required name="aadhar" value={aadhar}
                                                                                    errorstatus={aadharNum(aadhar) !== undefined && notEqualsZero(aadhar)}
                                                                                    errormessage={aadharNum(aadhar)}
                                                                                    onChange={(event) => setAadhar(event.target.value)} />
                                                                            </div>
                                                                            <div className={`field-box ${isShow !== 2 && "d-none"}`}>
                                                                                <label htmlFor="name" className="form-label">
                                                                                    Driving License Number
                                                                                </label>
                                                                                <DefaultInput id="name" type="text" className="form-control" disabled={aadhar.length >= 16 ? true : false} required name="aadhar" value={aadhar}
                                                                                    errorstatus={drivingLicense(aadhar) !== undefined && notEqualsZero(aadhar)}
                                                                                    errormessage={drivingLicense(aadhar)}
                                                                                    onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                                                                            </div>
                                                                            <div className={`field-box ${isShow !== 4 && "d-none"}`}>
                                                                                <label htmlFor="name" className="form-label">
                                                                                    Passport Number
                                                                                </label>
                                                                                <DefaultInput id="name" type="text" className="form-control" required name="aadhar" value={aadhar}
                                                                                    errorstatus={passport(aadhar) !== undefined && notEqualsZero(aadhar)}
                                                                                    errormessage={passport(aadhar)}
                                                                                    onChange={(event) => setAadhar(event.target.value.toUpperCase())} />
                                                                            </div>
                                                                            <div
                                                                                className={`field-box ${isShow !== 3 && "d-none"}`}
                                                                            >
                                                                                <label htmlFor="name" className="form-label">
                                                                                    Other Document Number
                                                                                </label>
                                                                                <DefaultInput id="name" type="text" className="form-control" required name="aadhar" value={aadhar} errorstatus={documentNum(aadhar) !== undefined && notEqualsZero(aadhar)} errormessage={documentNum(aadhar)} onChange={(event) => setAadhar(event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 upload-area" >
                                                                            <div className="upload-formate mb-3">
                                                                                <h6 className="title mb-1">
                                                                                    Front Image
                                                                                </h6>
                                                                                <div className=" text-smallest mb-1" >
                                                                                    <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="brows-file-wrapper">
                                                                                <input name="file" id="file3" type="file" className="inputfile"
                                                                                    data-multiple-caption="{count} files selected" onChange={handleChangeIdentity} required />
                                                                                {localFront === '' ?
                                                                                    <label htmlFor="file3" title="No File Choosen">
                                                                                        <i className="ri-upload-cloud-line"></i>
                                                                                        <span className="text-center mb-2">Choose a File</span>
                                                                                        < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                    </label>
                                                                                    : <label htmlFor="file3" title="No File Choosen">
                                                                                        <i className=" text-success ri-check-double-fill"></i>
                                                                                        <span className="text-center mb-2">File Uploaded</span>
                                                                                        <span className="file-type text-center mt--10" >{localFront?.name}</span>
                                                                                    </label>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 upload-area" >
                                                                            <div className="upload-formate mb-3">
                                                                                <h6 className="title mb-1">
                                                                                    Back Image
                                                                                </h6>
                                                                                <div className=" text-smallest mb-1" >
                                                                                    <span>  (Only JPEG, PNG & JPG formats and file size upto 5MB are supported)</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="brows-file-wrapper">
                                                                                <input name="file" id="file4" type="file" className="inputfile"
                                                                                    data-multiple-caption="{count} files selected" onChange={handleChangeIdentity2} required />

                                                                                {localBack === '' ?
                                                                                    <label htmlFor="file4" title="No File Choosen">
                                                                                        <i className="ri-upload-cloud-line"></i>
                                                                                        <span className="text-center mb-2">Choose a File</span>
                                                                                        < span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                                                                                    </label>
                                                                                    : <label htmlFor="file4" title="No File Choosen">
                                                                                        <i className=" text-success ri-check-double-fill"></i>
                                                                                        <span className="text-center mb-2">File Uploaded</span>
                                                                                        <span className="file-type text-center mt--10" >{localBack?.name}</span>
                                                                                    </label>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="col-md-12 mb-4">
                                                                            <div className="field-box">
                                                                                <label htmlFor="name" className="form-label">TON Address</label>
                                                                                <input id="name" type="text" placeholder="" className="form-control" value={tonAddress} name="tonAddress" onChange={(event) => setTonAddress(event.target.value)} required />
                                                                            </div>
                                                                        </div> */}

                                                                        {/* </div> */}
                                                                        <div className="" >
                                                                            <button type='button' className=" mt-4  btn w-100 btn-gradient btn-xl  justify-content-center" onClick={() => handleKyc(infoCountry, kycType, firstName, lastName, gender, infoDob, address, infoState, city, zipCode, aadhar, panCard, localFront, localBack, localSelfie, localPanCard, docType, rePanCard, middleName, emailOtp, emailId, mobileNumber, mobileOtp)}>
                                                                                <span>Submit for verification</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>}
                                                    </div>
                                                </form>
                                            </div>
                            }
                        </div>
                    </div >
                </div >
            </section >


        </>
    );
}

export default KycPage



import React, { useEffect, createContext, useState } from 'react';
import AuthService from '../../api/services/AuthService';
import LoaderHelper from '../../customComponents/Loading/LoaderHelper';
import { alertErrorMessage } from '../../customComponents/CustomAlertMessage';
export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [loginDetails, setLoginDetails] = useState([]);
  const [newStoredTheme, setNewStoredTheme] = useState('');
  
  const [activeTab, setActiveTab] = useState("");
  const token = localStorage.getItem('token');


  const handleUserDetails = async () => {
    LoaderHelper.loaderStatus(false);
    await AuthService.getDetails().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserDetails(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  function generateSocketUniqueId(length = 15) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueId = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueId += characters.charAt(randomIndex);
    }
    sessionStorage.setItem("socketId", uniqueId)
}


  useEffect(() => {
    if (token) {
      handleUserDetails();
    }
    generateSocketUniqueId()
  }, [token]);




  const handleTheme = () => {
    const htmlTag = document.documentElement;
    const currentTheme = htmlTag.getAttribute('data-theme');
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    htmlTag.setAttribute('data-theme', newTheme);
    setNewStoredTheme(newTheme);
  };

  React.useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const htmlTag = document.documentElement;
    if (storedTheme) {
      htmlTag.setAttribute('data-theme', storedTheme);
    } else {
      const defaultTheme = 'light';
      htmlTag.setAttribute('data-theme', defaultTheme);
      localStorage.setItem('CurrentTheme', defaultTheme);
    }
  }, []);


  return (
    <>

      <ProfileContext.Provider value={{ userDetails, setUserDetails, handleUserDetails, setLoginDetails, loginDetails, handleTheme, newStoredTheme,activeTab, setActiveTab }}>
        {props.children}
      </ProfileContext.Provider>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

const TermsCondition = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> Terms And Condition </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Terms And Condition</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="row align-items-center py-5">
                  <div className="col-lg-7 ">
                    <div>
                      <h3 className="title text-white mb-3"> Who We Are </h3>
                      <p>CV Trade Network was founded in 2022 by a team of young and passionate crypto enthusiasts, all of whom have a background in cutting-edge technologies. Our team members are committed to exploring the vast potential of crypto and blockchain technologies to revolutionize the financial industry. </p>
                      <p>Before starting CV Trade Network, our team members have developed multiple tech products that have assisted consumers in making informed decisions about various industries. However, our focus on crypto and blockchain led us to establish CV Trade Network, a platform for trading cryptocurrencies.</p>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <img src="/images/fe_1.png" className="img-fluid career_img" />
                  </div>
                </div>
                <hr />
                <div className="row align-items-center pb-5">
                  <div className="col-lg-12">
                    <div>
                      <h3 className="title text-white mb-3"> What We Believe </h3>
                      <p>At CV Trade Network, we firmly believe that cryptocurrencies and blockchain technology are the future of money. We are committed to promoting an open, transparent, and clear communication process with our users, which we believe is key to building trust in the industry.</p>
                      <p>We understand that not everyone is familiar with crypto, which is why we place great emphasis on creating a simple, aesthetic, and easy-to-use interface for our users. We believe that crypto should be accessible to everyone, not just a select few.</p>
                      <p>At CV Trade Network, we not only support established currencies like Bitcoin but also niche altcoins with real benefits. We are committed to providing responsive and immediate customer support to our users, ensuring that they have a seamless and hassle-free experience on our platform</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </section>
  );
}

export default TermsCondition;
import moment from 'moment';
import React from 'react';
import { ApiConfig } from '../../api/apiConfig/apiConfig';

const Message = ({ message, currentfriend, scrollRef, typingMessage }) => {
     const userId = localStorage.getItem("userId")

     return (
          <>
               <div className="chat_body scroll_y" id='msg-box'  >
                    <div className="msg_history scroll_bar">
                         {
                              message && message.length > 0 ? message.map((m, index) =>
                                   m.senderId == userId ?
                                        <div className='outgoing_msg' ref={index === message.length - 1 ? scrollRef : null} key={index}>
                                             <div className="sent_msg" >
                                                  <p> {m?.message?.text == '' ? <a href={(ApiConfig.baseUrl + m?.message?.image)} target='_blank' rel="noreferrer"> <img src={ApiConfig?.baseUrl + m?.message?.image} alt='' className="img-fluid img msg_img shadow-soft " /> </a> : m?.message?.text} </p>
                                             </div>
                                             <div className='msg_time' >
                                                  <small> {moment(m.createdAt).startOf('mini').fromNow()}</small>
                                             </div>
                                        </div>
                                        :

                                        <div className='incoming_msg' ref={index === message.length - 1 ? scrollRef : null} key={index} >
                                             <div className="incoming_msg_img avatar shadow-soft "><span>
                                                  <img width="50px" className='avatar ' src={currentfriend?.profilepicture ? ApiConfig.baseUrl + currentfriend?.profilepicture : "/images/user.png"} alt='' />
                                             </span></div>
                                             <div className='received_msg'>
                                                  <div className='received_withd_msg'>

                                                       <p> {m?.message?.text === '' ? <a href={(ApiConfig.baseUrl + m?.message?.image)} target='_blank' rel="noreferrer"><img src={(ApiConfig.baseUrl + m?.message?.image)} />  </a> : m?.message?.text}</p>
                                                  </div>
                                                  <div className='time'>
                                                       <small>    {moment(m.createdAt).startOf('mini').fromNow()}</small>
                                                  </div>
                                             </div>

                                        </div>
                              ) : <div className='incoming_msg'>
                                   <img className='avatar shadow-soft' width="50px" src={currentfriend?.profilepicture ? ApiConfig.baseUrl + currentfriend?.profilepicture : "/images/user.png"} alt='' />
                                   <strong>{currentfriend?.firstName || "User"} Connected </strong>
                              </div>
                         }

                    </div>
               </div>

          </>
     )
};

export default Message;


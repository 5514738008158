import React, { useState, useEffect, useContext } from "react";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponents/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { Navigate } from "react-router-dom";
import { ProfileContext } from "../../../context/ProfileProvider";
const CurrencyPrefrence = (props) => {

    const { handleUserDetails } = useContext(ProfileContext);
    const [currencyType, setCurrencyType] = useState();

    useEffect(() => {
        setCurrencyType(props?.userDetails?.currency_prefrence);
    }, []);

    const handleCurrency = async (currencyType) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.setCurrency(currencyType).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                    handleUserDetails();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <div className="tab-pane" id="CurrencyPill" role="tabpanel" aria-labelledby="Currency-pill">
            {/* <div className="upload-formate mb-6 d-flex justify-content-center align-items-center  ">
                <div>
                    <h3 className="mb-1 text-center">
                        Currency Preference
                    </h3>
                    <p className="formate text-center">
                        Select your preferred display currency for all markets
                    </p>
                </div>
            </div> */}
            <div className=" row py-4">
                <div className="col-md-12 col-lg-10 m-auto">
                    <div className="card twofa_card">
                        <div className="card-body" >
                            <div className="card-header mb-md-4" >
                                <h3> Currency Preference </h3>
                                <p className="mb-0 text-muted" > Select your preferred display currency for all markets </p>
                            </div>

                            <div className="card-card-body_inner py-2 py-md-5" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="card-radio-btn mb-3 label">
                                            <input type="radio" name="bitcoin" className={`card-input-element d-none ${currencyType == "USDT" ? "active" : ""}`} id="USDT" onChange={(e) => setCurrencyType(e.target.id)} />
                                            <div className="card card-body">
                                                <img src="/images/coins/tether.png" className="img-fluid check_img" />
                                                <div className="content_head mx-3">Tether USD (USDT)</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="card-radio-btn mb-3 label ">
                                            <input type="radio" name="bitcoin" className={`card-input-element d-none ${currencyType == "BTC" ? "active" : ""}`} id="BTC" onChange={(e) => setCurrencyType(e.target.id)} />
                                            <div className="card card-body">
                                                <img src="/images/coins/bitcoin.png" className="img-fluid check_img" />
                                                <div className="content_head mx-3">Bitcoin (BTC)</div>
                                            </div>
                                        </label>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label className="card-radio-btn mb-3 label">
                                            <input type="radio" name="bitcoin" className={`card-input-element d-none ${currencyType == "INR" ? "active" : ""}`} id="INR" onChange={(e) => setCurrencyType(e.target.id)} />
                                            <div className="card card-body">
                                                <img src="/images/coins/rupee.png" className="img-fluid check_img" />
                                                <div className="content_head mx-3"> Rupee (INR)</div>
                                            </div>
                                        </label>
                                    </div> */}
                                    <div className="col-md-6">
                                        <label className="card-radio-btn label">
                                            <input type="radio" name="bitcoin" className={`card-input-element d-none ${currencyType == "BNB" ? "active" : ""}`} id="BNB" onChange={(e) => setCurrencyType(e.target.id)} />
                                            <div className="card card-body">
                                                <img src="/images/coins/bnb.png" className="img-fluid check_img" />
                                                <div className="content_head mx-3">BNB</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button className=" btn custom-btn btn-block btn-xl  mt-5 justify-content-center w-100" type="button" onClick={() => handleCurrency(currencyType)}><span> Save Currency Preference </span></button>



                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default CurrencyPrefrence;
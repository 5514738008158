import React, { useState, useEffect } from "react";

import moment from "moment";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponents/CustomAlertMessage";

const ActivitylogPage = () => {

    const [activity, setActivity] = useState("");
    const [updatedactivity, setupdatedactivity] = useState()
    const [activityLength, setactivityLength] = useState()
    const [updatedLength, setupdatedLength] = useState(5);
    useEffect(() => {
        activityLogs();
    }, []);

    const activityLogs = async () => {
        LoaderHelper.loaderStatus(true)
        await AuthService.getActivityLogs().then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result?.success) {
                setupdatedactivity(result?.data ? result?.data?.slice(0, updatedLength) : []);
                setactivityLength(result?.data ? result?.data?.length : 0);
                setActivity(result?.data ? result?.data : [])
                window.scrollTo({
                    top: 200,
                    left: 0,
                    behavior: "smooth"
                });
            } else {
                alertErrorMessage(result?.message);
            }
        });
    };

    useEffect(() => {
        if (updatedLength > 5) {
            const mql = window.matchMedia('(max-width: 768px)');
            if (mql?.matches) {
                window.scrollTo({
                    top: document.documentElement.scrollHeight - 1900,
                    left: 0,
                    behavior: "smooth"
                })
            } else {
                window.scrollTo({
                    top: document.documentElement.scrollHeight - 1300,
                    left: 0,
                    behavior: "smooth"
                })
            }

        }
    }, [updatedactivity]);

    useEffect(() => {
        if (activity) {
            setupdatedactivity(activity.slice(0, updatedLength));
        }
    }, [updatedLength]);

    return (
        <div className="tab-pane" id="ActivityPill" role="tabpanel" aria-labelledby="Activity-pill">
            <div className="card twofa_card">
                <div className="card-body" >
                    <div className="card-header mb-md-4" >
                        <h3> Activity Logs</h3>
                        <p className="mb-0 text-muted" >  Your Activity Logs display for all Activity </p>
                    </div>
                    {Object.keys(activity).length === 0 ?
                        <div className="favouriteData">
                            <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                            <br />
                            <p className="mt-3 mb-4" > No Data Found. </p>
                        </div>
                        :
                        <>
                            <div className="card-card-body_inner pt-3" >
                                <div className="sessions__table" >
                                    <div className="activity-wrapper">
                                        <div className="custom-history">
                                            {updatedactivity.length > 0 ?
                                                updatedactivity.map(item =>
                                                    <div className="single-item-history d-flex-center">
                                                        <div className="content">
                                                            <span className="text-muted" >Status: &nbsp;</span><b>{item?.Activity}</b>
                                                            <p className="mb-0" > <samll className="text-muted" >IP:&nbsp; {item?.IP} </samll> </p>
                                                        </div>
                                                        <small className="date align-self-start text-muted">



                                                            {moment(item?.date).format('MMMM Do YYYY, h:mm:ss a')}
                                                        </small>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add_pls  d-flex justify-content-center">
                                {updatedLength >= activityLength ? '' :
                                    <button className="btn btn-sm  btn-link text-decoration-none text-muted text-center" type="button"
                                        onClick={() => {
                                            setupdatedLength(updatedLength + 5);
                                        }}
                                    > <i className="ri-restart-line me-2 "></i> Load more </button>}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ActivitylogPage;
import {createStore,compose,combineReducers,applyMiddleware} from 'redux';

import {thunk} from 'redux-thunk';
import {messengerReducer} from '../store/reducers/messengerReducer';

const rootReducer = combineReducers({
     messenger : messengerReducer
})

const middleware = [thunk];

const store = createStore(rootReducer,compose(applyMiddleware(...middleware),

));

export default store;








import React, { useState } from "react";
import { validPassword, notEqualsZero, matchPassword } from "../../../utils/Validation";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import DefaultInput from "../../../customComponents/DefaultInput";

const SecurityPage = () => {
    const [password, setPassword] = useState('');
    const [conPassword, setConPassword] = useState('');
    const [oldpassword, setOldpassword] = useState('');
    const [verificationcode, setVerificationcode] = useState('');
    const [email_or_phone, setEmailorPhone] = useState('');

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "password":
                setPassword(event.target.value);
                break;
            case "conPassword":
                setConPassword(event.target.value);
                break;
            case "oldpassword":
                setOldpassword(event.target.value);
                break;
            case "verificationcode":
                setVerificationcode(event.target.value);
                break;
            case "email_or_phone":
                setEmailorPhone(event.target.value);
                break;
            default:
                break;
        }
    }

    const handleGetOtp = async (email_or_phone, resend) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(email_or_phone, resend).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };


    const handleSecurity = async (oldpassword, password, conPassword, verificationcode, email_or_phone) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.setSecurity(oldpassword, password, conPassword, verificationcode, email_or_phone).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    alertSuccessMessage(result.message);
                    setPassword("");
                    setConPassword("");
                    setOldpassword("");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage(result.message);
            }
        });
    }


    return (
        <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
            <div className="upload-formate mb-6 d-flex justify-content-center align-items-center">
                <div>
                    <h3 className="mb-1 text-center">
                        Security
                    </h3>
                    <p className="formate mb-0 text-center">
                        Fill the form below to change your password.
                    </p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12 col-lg-10 m-auto">
                    <div className="card twofa_card">
                        <div className="card-body" >
                            <div className="card-header" >
                                <h3>Change password </h3>
                                <p className="mb-0 text-muted" >change your password</p>
                            </div>
                            <hr />
                            <div className="card-card-body_inner py-2 py-md-2" >
                                <form>
                                    <div className="row">
                                        <div className="col-md-12 mb-4">
                                            <div className="field-box">
                                                <label for="text" className="form-label">Current Password<em className="text-danger" >*</em></label>
                                                <DefaultInput
                                                    errorstatus={
                                                        validPassword(oldpassword)
                                                    }
                                                    errormessage={validPassword(oldpassword)}
                                                    type="text" name="oldpassword" className="form-control" aria-describedby="password"
                                                    placeholder="Enter Your Password.." value={oldpassword} onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div className="field-box">
                                                <label for="text" className="form-label">New Password<em className="text-danger" >*</em></label>
                                                <DefaultInput type="text" className="form-control" name="password" placeholder="Enter New Password" value={password} errorstatus={validPassword(password) !== undefined && notEqualsZero(password)} errormessage={validPassword(password)} onChange={handleInputChange} />

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div className="field-box">
                                                <label for="text" className="form-label">Confirm New Password<em className="text-danger" >*</em></label>
                                                <DefaultInput type="text" className="form-control" name="conPassword" placeholder="Enter Confirm Password" value={conPassword} errorstatus={validPassword(conPassword) !== undefined && notEqualsZero(conPassword) || matchPassword(password, conPassword)} errormessage={validPassword(conPassword) || matchPassword(password, conPassword)} onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div className="col-md-7 mb-4">
                                            <div className="field-box">
                                                <label for="text" className="form-label">Enter Email or PhoneNumber<em className="text-danger" >*</em></label>
                                                <div className=" field-otp-box" >
                                                    <input type="text" className="form-control" name="email_or_phone" placeholder="Enter Email or PhoneNumber" value={email_or_phone} onChange={handleInputChange} />
                                                    <button type="button" class="btn btn-xs  custom-btn" onClick={() => handleGetOtp(email_or_phone)}><span> GET OTP   </span></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5 mb-4">
                                            <div className="field-box">
                                                <label for="text" className="form-label">Enter Verification Code<em className="text-danger" >*</em></label>
                                                <input type="text" className="form-control" name="verificationcode" placeholder="Enter Verification Code" value={verificationcode} onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-2">
                                            <div className="field-box text-end pt-2">
                                                <button className="btn custom-btn btn-block w-100 btn-xl" type="button" onClick={() => handleSecurity(oldpassword, password, conPassword, verificationcode, email_or_phone)}><span>Submit</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecurityPage;
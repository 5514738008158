import React from "react";
import { Link } from "react-router-dom";

const AmlPolicy = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> AML POLICY </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">AML POLICY</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">KYC / AML POLICY FOR CUSTOMER IDENTIFICATION, PREVENTION OF MONEY LAUNDERING, AND TRANSACTION MONITORING</h3>
                      <p>This policy outlines the KYC and AML requirements for using the CVtrade website (https://cvtrade.io/ ) and mobile application called "CVtrade: Cryptocurrency App" (collectively referred to as the "Platform"). The Platform is owned and managed by Angelic Infotech Private Limited, a company incorporated under the Companies Act, 2013, operating under the brand name of "CVtrade".
                        By accessing the Platform, you acknowledge that this KYC/AML Policy is a binding agreement between you and CVtrade. CVtrade reserves the right to modify, add or remove parts of this policy at any time without prior notice. It is your responsibility to review this policy periodically for updates. Your use of the Platform after any modifications will indicate your acceptance of the changes. This KYC/AML Policy is also subject  to  the Terms  of  Use and Privacy  Policy  of  the Platform.
                        By accessing the Platform, you agree to allow CVtrade to continuously monitor and collect data and information about your activities on the Platform for the purpose of this KYC/AML Policy.</p>
                    </div>
                  </div>

                </div>
                <hr />
                <div className="row align-items-center pb-5">
                  <div className="col-lg-12">
                    <div>
                      <h3 className="title mb-3"> MEANING OF TERMS USED IN THIS POLICY: </h3>
                      <p> • The term "Applicable Law" refers to any existing and
                        enforceable legal regulations, such as statutes, ordinances,    rules,    judgments,    orders,    decrees, guidelines, policies, directives, requirements, or other governmental restrictions in India. This includes the Prevention of Money Laundering Act 2002 ("PMLA"), the Prevention of Money Laundering (Maintenance of Records) Rules 2005 ("PML Rules"), as well as various applicable regulations, rules, and guidelines of the Reserve Bank of India, its constituents/payment system providers, and the Computer Emergency Response Team, India, which may be updated or replaced from time to time.
                      </p>
                      <p> • The term "Crypto(s)" denotes virtual digital assets that are cryptographically secured and represented as a digital value or contractual right, using distributed ledger technology. These can be traded, stored, or transferred electronically using the Platform, including bitcoin (BTC) and Ether (ETH), among others.
                      </p>
                      <p>   • The   term   "Customer"/"User"/"You"   refers   to   any individual who uses or accesses the Platform for trading in Cryptos.
                      </p>
                      <p>    • "Customer Due Diligence (CDD)" is the process of identifying and verifying the identity of the customer using a reliable and independent source of documents, data, or information.
                      </p>
                      <p>   • "Officially Valid Document/OVD" refers to documents such as passport, driving license, proof of possession   of   an   Aadhaar   Number,   or   voter's identity card issued by the Election Commission of India. For this definition, "Aadhaar Number" means an identification number as defined under the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016.
                      </p>
                      <p>  • The term "Person" refers to an individual who is an Indian citizen-resident and is above the age of eighteen (18) years.
                      </p>

                      <p>      • A "Politically Exposed Person" (PEP) is an individual who is authorized to perform prominent public functions in a country, such as governors of the state, members of Parliament, military officers, senior government and judicial executives, and heads of local bodies such as municipal corporations, among others. Family members or close relatives of such individuals may also be considered as PEPs. </p>

                      <p>    • The term “Suspicious Transaction” means any transaction, including attempted transactions on the Platform, which, at CVtrade’s sole discretion:
                        -gives rise to a reasonable suspicion that it may involve proceeds of a crime or an offense, irrespective of the value involved; or
                        -appears   to   be   made    under    circumstances of     unusual     or     unjustified  complexity,  or  in violation of any Applicable Law; or
                        -appears to have no economic rationale or legitimate purpose; or
                        -gives rise to a reasonable suspicion that it may involve financing of activities related to terrorism.
                        Terrorism includes transactions involving funds suspected to be linked or related to, or used for terrorism, terrorist acts, or by a terrorist, terrorist organization, or those who finance or attempt to finance terrorism. </p>

                      <p>    • The term “User Account” or “CVtrade Account” means the account created on the Platform, through which the User provides instructions for Crypto trading to CVtrade.
                      </p>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">KNOW YOUR CUSTOMER NORMS:</h3>

                      <p>
                        (i.) The fundamental principle for identifying any individual who opens and operates a User Account is  KYC,  which  stands  for  'Know  Your  Customer'. This method enables institutions to effectively confirm and authenticate the identity of a customer.
                      </p>
                      <p>(ii.) Customer identification involves verifying the documents and information provided by the customer. The objectives of KYC are as follows:
                        <br />
                        <br />
                        <p>-Ensuring appropriate customer identification. </p>
                        <p> -Monitoring transactions of a suspicious nature. </p>
                        <p>  -Ensuring that the proposed customer is not an undischarged insolvent. </p>
                        <p> -Reducing the risk of fraud. </p>
                        <p> -Preventing the opening of Benami accounts with fictitious names and addresses. </p>
                        <p> -Identifying and removing undesirable customers. </p>
                      </p>
                      <p>  (iii) Platform done not support or sign-ups non individual entities (e.g. Corporate account,) Trust Account. This it prevent us from entering into a relationship with shell bank /organization.
                      </p>
                    </div>
                  </div>

                </div>
                <hr />


                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">DECLARATIONS AND OBLIGATIONS:</h3>
                      <h6>Declarations and Disclosure of Information by the Platform:</h6>
                      <p>

                        (i.) When a User chooses to trade using the Platform or applies to be a Customer, CVtrade will verify their identity. CVtrade will collect and request documents and data from the User as necessary to establish and verify their identity for KYC purposes and to identify any Suspicious Transactions on the Platform. All information provided by the User for KYC and customer identification purposes, as well as information on Suspicious Transactions, must be accurate and up-to-date. CVtrade may also use various software, technology, or other means, either directly or through service providers/vendors,   to   verify   the   User's   identity and the information provided. By using the Platform, the User consents to such identity verification and KYC checks.
                      </p>
                      <p>(ii.) CVtrade will request documents and data for KYC and customer identification purposes from the User and will access and use them in accordance with this Policy, Applicable Laws, and the Privacy
                        Policy accessible at https://cvtrade.io/privacy- policy.
                      </p>



                      <p>(iii.) CVtrade will make reasonable efforts to verify the identity, address, and other details and documents submitted by the Users, either directly or through third-party vendors/service providers, using legally and operationally tenable methods,
                        including but not limited to:
                        a. Verification of PAN/e-PAN through government sources; or
                        b. Verification of Masked/Offline Aadhaar/Proof of Possession of Aadhaar under the Aadhaar (Targeted Delivery of Financial and Other
                        Subsidies, Benefits and Services) Act, 2016; or
                        c. Verification	of	Passport	issued	under	the Passports Act, 1967; or
                        d. Verification of Voter ID card issued by the Election Commission of India; or
                        e. Any additional documents that the Platform may require from time to time.
                      </p>

                      <p> (iv.) As a User, you agree to provide the necessary documentation and information for KYC checks promptly to create and maintain your Platform Account, through which you may instruct the Platform.
                      </p>
                      <p> (v.) Your failure to provide the required KYC documents, identification documents, or information may limit or completely prevent your use of the Platform and related services.
                      </p>
                      <p> (vi.) CVtrade may modify the list of required documents, verifications, and information at its sole discretion without prior notice.
                      </p>
                      <p> (vii.) CVtrade has the right to examine or request additional information and documents to verify
                        your identity and financial position, including the sources
                        of your funds and the details of the Crypto wallet from which you transferred or received any Crypto.
                      </p>
                      <p>   (viii.) If you do not comply with the requirements, CVtrade will not allow you to create or operate the User Account or carry out Crypto transactions through the Platform.
                      </p>
                      <p>  (ix.) You agree to provide any additional documents required by CVtrade immediately upon receiving a written request, to comply with Applicable Laws, CVtrade policies, or a request from any law enforcement authority.
                      </p>
                      <p>  (x.) You further guarantee that you will provide accurate, complete, and genuine KYC documents, information, and data to CVtrade, its delegates, agents, or representatives.
                      </p>
                      <p>  (xi.) If CVtrade considers any transaction or series of transactions to be a Suspicious		Transaction	or reasonably suspects that it may involve proceeds of crime or be used for illegal activities, or if CVtrade	receives	requests	from	any	banking partner/payment			system		provider		or participant/statutory/regulatory/supervisory/law enforcement authority, CVtrade will report such Suspicious Transactions to the Authorities, as well as use,	retain,	and share your personal data, documents, and information with the Authorities.
                      </p>
                      <p> (xii.) In such circumstances, CVtrade may block and freeze your access to the Platform and your CVtrade Account and increase future monitoring of your User Account. You, as a User, warrant to provide all necessary assistance, support, and cooperation, including additional documents, to
                        verify identity or transaction details.
                      </p>
                      <p> (xiii.) CVtrade does not allow the opening or maintenance of anonymous User Accounts, accounts created under fictitious names, or more than one account per person whose identity has not been revealed or verified.
                      </p>

                    </div>
                  </div>

                </div>

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">.User Responsibilities</h3>
                      <p>
                        (i.) By using the Platform, you agree to comply with this KYC/AML Policy, as updated periodically, and all applicable laws and regulations, and to use the Platform exclusively for lawful purposes.
                      </p>

                      <p>(ii.) You must not use the Platform to engage in any illegal, criminal, or anti- national activities, or to finance such activities under any circumstances.
                      </p>



                      <p>(iii.) Impersonation of another person or misrepresenting yourself on the Platform is strictly prohibited.
                      </p>

                      <p> (iv.) You warrant and undertake that you will not participate in any Benami transactions or transactions that violate any Applicable Laws, this Policy, or any other policy or directive issued by CVtrade from time to time.
                      </p>


                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">POLICY FOR CUSTOMER APPROVAL" OR "PROCEDURE FOR CUSTOMER ENDORSEMENT:</h3>
                      <p>
                        .Opening a CVtrade Account
                      </p>
                      <p>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>(i.)</th>
                              <th>AnyIndian</th>
                              <th>resident can</th>
                              <th>open</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td></td>
                              <td>CVtrade</td>
                              <td>Account within</td>
                              <td>India's</td>
                            </tr>
                          </tbody>
                        </table>
                      </p>



                      <p>(ii.) To activate their CVtrade Account, the User must provide the following: </p>

                      <p>a. Permanent Account	Number (PAN)
                        issued	by the Income Tax Authorities, </p>

                      <p>b. Documents for identification and proof of
                        residence (Aadhaar/Voter ID/Passport), </p>

                      <p> c. Live selfie from the camera.</p>

                      <p> (iii.) After the account opening process, the User needs to add and verify their bank account/UPI ID to make INR deposits/withdrawals.
                      </p>

                      <p>
                        (iv.) The documents/data mentioned above will establish the identity of the User, but additional information may be collected while opening the CVtrade Account, such as: <p></p>
                        <p> a. Annual income, </p>
                        <p> b. Occupation, </p>
                        <p>  c. Politically Exposed Person (PEP) status, </p>
                        <p> d. Trading experience, and </p>
                        <p>  e. Marital status. </p>
                      </p>
                      <p>(v.) CVtrade  will  activate  the  User's  CVtrade Account only when all the documents and information mentioned above or any additional information requested by CVtrade have been
                        verified to its satisfaction.</p>
                      <p>
                        (vi.) CVtrade will maintain an audit trail of any upload/modification/download.</p>
                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">MEASURES FOR PLATFORM SECURITY:</h3>
                      <p><strong> (i.) </strong> Before registering a CVtrade Account, we make reasonable efforts to ensure that:</p>


                      <p>   a. Users are not accessing the Platform under an anonymous or fake name.</p>

                      <p>   b. No CVtrade Account is made operational and the User is not allowed to use our services if we are  unable  to  verify  the  User's  identity,  obtain required documents or if the documents/information provided by the User are not reliable or if the User does not cooperate with us.</p>

                      <p>  c. We follow CDD (Customer Due Diligence) procedures before opening any account or executing trades.</p>

                      <p> d. If any suspicious transaction done by users, in that case users profile will be update according transaction behaviour category.

                      </p>



                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">CUSTOMER DUE DILIGENCE (CDD) TYPES:</h3>
                      <p>There are two types of Customer Due Diligence (CDD) that CVtrade may employ, depending on the level of risk associated with the customer.</p>


                      <p>  (i.) Basic Due Diligence entails collecting and verifying identity proof, address proof, and a photograph to confirm  the  user's  identity.  This  is  carried  out  by examining the information and documents submitted by the User.</p>

                      <p> (ii.) Enhanced Due Diligence (EDD) involves additional diligence measures that go beyond Basic Due Diligence. EDD measures may include, but are not limited to, checking whether a User is a Politically Exposed Person (PEP) or has links to terrorist activities or groups, monitoring the account activity of such users, and so on.</p>

                      <p>  (iii) CVtrade periodically updates information on a risk basis.If any suspicious transaction or activity done by users, in that case users profile will be update according transaction behaviour category.
                        Depending on the risk profile of the client which is measured by transaction value, frequency, product exposures etc we periodically review clients under following schedule:</p>

                      <p>     1. High Risk client - once every 6 months</p>
                      <p>   2. Medium Risk client - once every year</p>
                      <p>  3. Low Risk client - once every 2 years </p>

                      <p>CVtrade shall perform Basic Due Diligence, Enhanced Due Diligence, or any other type of due diligence activity or measures that it deems necessary for a User to register or use the Platform, in its sole discretion and/or in accordance with Applicable Laws."</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">ANTI-MONEY LAUNDERING (AML) STANDARDS:</h3>
                      <p>According to the Prevention of Money-Laundering Act, 2002 and the Prevention of Money-Laundering (Maintenance of Records) Rules, 2005, Regulated Entities (REs) are required to follow certain customer identification procedures when registering with the Platform and conducting transactions, including establishing account- based relationships and monitoring transactions. Although CVtrade is not an RE, it has voluntarily adopted the KYC/AML/CFT processes under the aforementioned Act and Rules.</p>


                      <p>  CVtrade is committed to fighting money laundering and terrorist financing and has implemented processes to prevent such activities on its Platform.</p>

                      <p> CVtrade has implemented certain steps with an objective to prevent any money laundering activity and/or terrorist financing on the Platform. Such processes being implemented are exhaustive in nature and   are   subject   to   change   as   required   under  any Applicable Law and/or as per CVtrade’s sole discretion.</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">CONTINUOUS TRANSACTION MONITORING:</h3>
                      <p>It is crucial to monitor and supervise CVtrade Accounts for any suspicious activities.</p>


                      <p>(i.) CVtrade will conduct ongoing due diligence by     monitoring     a     User's   CVtrade  Account activities through the Platform to ensure that they are in line with their risk profile and source of funds.</p>

                      <p>(ii.) If CVtrade detects any suspicious transactions, doubts the adequacy or veracity of previously obtained User identification data, or suspects money laundering or financing of terrorism, CVtrade temporary suspends user’s account and it may review due diligence measures, verify the User's   identity   again,   and   request   additional information. Users must provide all requested information.</p>


                      <p> (iii.) Monitoring customer activity and transactions is essential to assess risk, know customers, and prevent financial crimes. However, the level of monitoring    depends    on    the    customer's    risk category. High-risk accounts require more intense monitoring..</p>

                      <p> (iv.) Certain transactions must be monitored, and CVtrade may freeze or suspend any CVtrade Account, block User access, or terminate accounts as determined by CVtrade in its sole discretion. Transactions that require close monitoring include very high account turnover inconsistent with the balance maintained, complex and unusually large transactions, and unusual patterns   that   are   inconsistent   with   the   User's normal and expected activity and lack an apparent economic rationale or lawful purpose..</p>

                      <p>(v.) CVtrade may use alerts when transactions are inconsistent with risk categorization. CVtrade will utilize updated customer profiles as part of its effective identification and reporting of suspicious transactions..</p>

                      <p>(vi) CVtrade uses Chainanalysis tools for transaction monitoring. Chainanalysis has complete database and have prefect tools to monitor redistrict address, Sanctioned Jurisdiction,Sanctioned Entity, Terrorist Financing, stolen fund, Darknet market, Child Abuse Material etc. All inflow/outflow transaction goes to chainanalysis tools via API then tools shows all details about the transaction, such as transaction category, risk category etc.
                        There are three types of Risk Category: High, Medium and Low.</p>

                      <p> (1) High Risk Category: If any transaction from sanctioned Jurisdiction,Sanctioned Entity, Terrorist Financing, stolen fund, Darknet market, Child Abuse Material the transaction notified in High Risk category. If any transaction notified into high risk category then CVtrade peremptory suspend user’s account and review due diligence measures, verify  the  User's  identity again, and request additional information. Users must provide all requested information such as purpose of transaction, source of deposited transaction etc. All high risk category transactions must reported to relevant authorities..</p>

                      <p>  (a) Medium Risk Category: If any transaction from high risk exchanges (Non kyc exchanges), gambling website, P2P exchanges etc. Medium risk category’s transaction not reported to relevant authorities. But user’s account will be continue on monitoring.
                        .</p>

                      <p>(b) Low Risk Category: If any source’s transaction from centralized wallet, Decentralized wallet, centralized exchanges (KYC) etc. Low risk category transaction is not reported to relevant authorities.
                        .</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">Suspicious transaction Reporting:</h3>
                      <p>It is crucial to monitor and supervise CVtrade Accounts for any suspicious activities.</p>


                      <p>When any transaction alert received in high risk category or high risk profile customer, Our compliance team review of that transaction and request for additional information or documents proof about the transaction.Then our compliance team & Principal officer make decision to report the transaction to authorities. All high risk category transaction report to
                        authorities but transaction amount should be more than 500 USD. We have a STR (Suspicious Transaction Reporting) format provided by<strong> FIU IND(fiuindia.gov.in). </strong>Compliance team make proper report to report the transaction, information which submitted such as <strong>Reporting Entity Name, Suspicious Activity Information, Contact &KYC details etc.</strong> The period for keeping and maintaining SAR records is at least 5 years.</p>


                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">RISK MANAGEMENT:</h3>
                      <p>(i.)		To prevent risks such as fraud, money laundering, inadvertent overdrafts, and Benami/fictitious	accounts,	customer identification is a critical requirement for opening an account.</p>


                      <p>(ii.) Failure to comply with transaction monitoring requirements, exceeding threshold limits, and not recording transactions may lead to intentional splitting/structuring of transactions to evade taxes, money laundering, and financing of terrorist
                        activities.</p>



                      <p>(iii.) CVtrade periodically conducts a Money Laundering (ML) and Terrorist Financing (TF) Risk Assessment to identify, assess, and mitigate money laundering and terrorist financing risks for Users, countries or geographic areas, and products, services, transactions, or delivery channels that is consistent with any national risk assessment conducted by a body or authority duly notified by the Central Government.</p>

                      <p>(iv.) As a best industry practice, CVtrade categorizes Users into low, medium, and high-risk categories based on their assessment and risk perception. The customer profile should contain information relating        to        the        customer's    identity, social/financial status, nature of the business activity, and risk categorization shall be undertaken based on these parameters.</p>

                      <p>(v.) User accounts should be periodically updated based on their risk category. Unless required under this Policy, Applicable Law, or for complying with any request of Authorities, the periodicity of such updating should not be less than once in tow (2) years for low-risk category customers and not less than
                        once in one (1) years for high and medium risk categories, once in Six (6) month for high risk categories. CVtrade reserves the right to change the periodicity at any time and from time to time at its sole discretion.</p>

                      <p>(vi.) When considering customer identity, CVtrade may also factor in the ability to confirm identity documents through online or other services offered by the issuing authorities. The customer profile is confidential, and details contained therein shall not be divulged for cross-selling or any purposes other than those specified in this KYC/AML Policy, Terms of Service, Privacy Policy, or any other policies of CVtrade made available on the Platform or otherwise informed to the User from time to time.
                      </p>

                      <p>(vii.)        CVtrade   assesses   each   customer's   risk categorization based on their experience, expertise, judgment, assessment, and risk perception of the customer and not merely based on any group or class they belong to.
                      </p>

                      <p>  (viii.)    CVtrade's risk assessment shall be reasonably documented, consider all relevant risk factors before determining the level of overall risk and the appropriate level and type of mitigation to be applied, be kept up-to-date, and be available to competent authorities and self-regulating bodies/Authorities if and as required under Applicable Laws.
                        Note: Customers risk category defined by transaction and activities behaviour
                      </p>

                      <p><strong>High Risk:</strong> If any users identified under High Risk category during transaction monitoring, unusual activities in account, transaction in suspicious pattern such as Deposits from multiple wallets and withdraw in single wallet & Withdrawal multiple wallets, suddenly High volume transaction in single day, etc.</p>

                      <p><strong>Medium Risk:</strong> If any users identified under medium Risk category during transaction monitoring.</p>
                      <p><strong>Low Risk:</strong> Except high risk and medium risk, all other users come in low risk.</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">PERIODIC UPDATION OF KYC’S:</h3>
                      <p>CVtrade carries out periodic updation of KYC for Users at intervals and through processes/documents determined at its discretion. The approach for periodic updation is based on the risk of the User, as described earlier.</p>


                      <p>a. No change in KYC information: If there is no change in the KYC information, CVtrade obtains a self- declaration from the User through their registered email and mobile number.</p>



                      <p>b. Change in address: If there is a change in the User's  address  details  only, CVtrade obtains a self- declaration of the new address from the User through their registered email and mobile number, along with valid proof for the change.</p>



                      <p>  c. Change in contact information such as phone number or email address: If there is a   change   in   the   User's contact details, the User can update the details with CVtrade by providing valid proof of change.
                      </p>
                      <p>    d. Change in any other information: If there is any change in the information provided during onboarding or periodic updates, the User should inform CVtrade with valid proof of change at <strong>compliance@cvtrade.io</strong>".</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">INTERNAL  CONTROLS MECHANISMS:</h3>
                      <h6 className="title mb-3">Record Preservation / Management:</h6>

                      <p>   (i.) CVtrade shall ensure that all information received for identification or due diligence purposes is used in accordance with its applicable terms and conditions. It shall also take necessary and reasonable steps to maintain, preserve, and report User information in accordance with its internal policies and standard operating procedures.
                      </p>
                      <p>  (ii.) CVtrade shall maintain the confidentiality, security, and protection of all User information collected or created by CVtrade in compliance with Applicable Law, and prevent unauthorized access, use, disclosure, publication or display of such information.
                      </p>
                      <p>   (iii.)    CVtrade shall keep records in the form of books or stored in a computer, of all User identity proof, documents and information provided, as well as all transactions undertaken by the User on the Platform, in compliance with Applicable Laws
                        and good industry practices.
                      </p>
                      <p>    (iv.) CVtrade shall maintain and report to the Authorities records of:
                        a. KYC details, documents, and data of all Users who open a User’s Platform Account on the Platform;
                        b. KYC details, documents, and data of all Users who undertake atransaction on the Platform; or
                        c. Your transactions on the Platform.
                      </p>
                      <p>   (v.) Regardless of anything to the contrary contained in the Terms of Use or Privacy Policy, any information obtained during due diligence measures or the creation and maintenance of the
                        CVtrade Account shall be maintained for as long as the account is operational and for a period of 5 (Five) years from the date the CVtrade Account ceases to exist or as specified under any Applicable Law/Authority.
                      </p>
                      <p> (vi.) Upon request, CVtrade will provide authorities with	access	to	identification	records	and
                        transaction data.
                      </p>
                      <p> (v!!)	In the event that CVtrade:
                        a. suspects that transactions may be involved in money laundering or terrorist financing, or
                        b. doubts the sufficiency or accuracy of previously obtained identification data,
                        appropriate actions will be taken to review the due diligence measures performed by the Platform or the information gathered (regarding the purpose and intended nature of the business relationship) from the User.
                      </p>
                    </div>
                  </div>

                </div>
                <hr />


                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">Employees Training on KYC’s:</h3>

                      <p>   (i.) CVtrade will implement screening mechanisms during its personnel recruitment and hiring process to ensure that high standards are maintained.</p>

                      <p>  (ii.) CVtrade will provide adequate training programs to its staff on KYC/AML/CFT policy. The training focus may vary depending on the employee's  role, such as frontline staff, compliance staff, risk management staff, audit staff, and staff dealing with new customers.</p>

                      <p>   (iii.) CVtrade may establish policies and FAQs, Do’s and   Dont’s   to   answer   Users'      queries      and questions and ensure their satisfaction while seeking information in furtherance of the Policy.</p>

                      <p>  (iv.) CVtrade has made every effort to ensure that this Policy adheres to applicable laws. The invalidity or unenforceability of any part of this Policy will not affect the validity or enforceability of the rest of this Policy. This Policy applies only to information collected by CVtrade through the Platform and does not apply to any other information.</p>

                      <p>   (v) Newly joining employees are first given training, then they are given work. And the already working employee is given training every 3 months.</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">Update of AML/CFT Program:</h3>

                      <p>  AML/CFT Updates regularly on requirement, Even when there is no requirement we review AML/CFT program every six month.</p>

                    </div>
                  </div>

                </div>
                <hr />

                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                    <div>
                      <h3 className="title mb-3">PRINCIPAL OFFICER:</h3>

                      <p> CVtrade has designated a Principal Officer, <strong>Mr. Pramod Yadav,</strong> to oversee compliance with the obligations outlined in this Policy and under relevant laws. Mr. Yadav can be contacted at <strong>compliance@cvtrade.io</strong> .
                        If you have any inquiries or complaints regarding this Policy or if you possess knowledge of any unlawful or suspicious activity involving a User, please contact us at <strong>compliance@cvtrade.io</strong></p>

                    </div>
                  </div>

                </div>
                <hr />

              </div>
            </div>
          </div>
        </div>
      </section>


    </section>
  );
}

export default AmlPolicy;
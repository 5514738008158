import React, { useEffect, useState } from "react";
import { $ } from "react-jquery-plugin";

import Header from "./Header";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { validAccountno, validIfscCode } from "../../../utils/Validation";
import DefaultInput from "../../../customComponents/DefaultInput";


const PaymentMethod = () => {
    const [holderName, setHolderName] = useState('');
    const [bankName, setBankname] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountType, setaccountType] = useState('SAVING');
    const [ifscCode, setIfscCode] = useState('');
    const [bankdetails, setBankDetails] = useState([]);
    const [upiDetails, setUpiDetails] = useState([])
    const [name, setName] = useState('')
    const [upiId, setUpiId] = useState('')
    const [id, setId] = useState('')
    const [bankId, setBankId] = useState('');
    const [upiImg, setUpiImg] = useState()

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "bankName":
                setBankname(event.target.value);
                break;
            case "branchAddress":
                setBranchAddress(event.target.value);
                break;
            case "accountNumber":
                setAccountNumber(event.target.value);
                break;
            case "accountType":
                setaccountType(event.target.value);
                break;
            case "ifscCode":
                setIfscCode(event.target.value);
                break;
            case "holderName":
                setHolderName(event.target.value);
                break;
            case "name":
                setName(event.target.value);
                break;
            case "upiId":
                setUpiId(event.target.value);
                break;
            default:
                break;
        }
    };

    const resetInput = () => {
        setHolderName("");
        setIfscCode("");
        setAccountNumber("");
        setBranchAddress("");
        setBankname("");
        setBankId("");
    };

    const AddBankDetails = (item) => {
        setBankId(item?._id);
        setHolderName(item?.account_holder_name);
        setIfscCode(item?.ifsc_code);
        setaccountType(item?.account_type);
        setAccountNumber(item?.account_number);
        setBranchAddress(item?.branch_name);
        setBankname(item?.bank_name);
    };

    const handleMessageQuery = async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.addBankDetails(accountType, bankName, holderName, accountNumber, ifscCode, branchAddress)
            if (result?.sucess) {
                alertSuccessMessage(result?.message);
                getPaymentDetails();
                resetInput()
                $("#add_bank_modal").modal('hide')
            }
            else alertErrorMessage(result?.message)

        } catch (error) { alertErrorMessage(error?.message); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const handleUpi = async (upiImg, upiId) => {
        try {
            var formData = new FormData();
            formData.append("upi_image", upiImg);
            formData.append("upi_id", upiId);
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.addUpiDetails(formData)
            if (result?.success) {
                alertSuccessMessage(result?.message);
                getPaymentDetails();
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) { alertErrorMessage(error?.message); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const EditBankDetails = async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, bankId) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.editBankDetails(accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, bankId)
            if (result?.sucess) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message);
                getPaymentDetails();
                resetInput();
                $("#add_bank_modal").modal("hide")
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    };

    const upiDetailsHandle = (item) => {
        setId(item?.id)
        setUpiImg(item?.upi_image)
        setUpiId(item?.upi_id)
    };

    const EditUpiDetails = async (id, upiImg, upiId) => {
        var formData = new FormData();
        formData.append("_id", id);
        formData.append("upi_image", upiImg);
        formData.append("upi_id", upiId);
        LoaderHelper.loaderStatus(true);
        await AuthService.editUpiDetails(formData).then(async result => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    getPaymentDetails();
                } catch (error) {
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    const getPaymentDetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.fetchPaymentMethods().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setUpiDetails(result?.data?.UpiDetails || [])
                setBankDetails(result?.data?.BankDetails || [])
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    const handleImage = (e) => {
        setUpiImg(e.target.files[0])
    };

    useEffect(() => {
        getPaymentDetails();
    }, []);


    return (
        <>
            <Header />
            <div className="buy_bar  " >
                <div className="container" >
                    <div className="bar_row" >
                        <div className="bar_left" >
                            <div className="bar_title" >
                                <h3> Payment Method </h3>
                                <p className="mb-0" data-bs-toggle="modal" data-bs-target="#payment_method_list" type="button"
                                    onClick={resetInput} >Add, Edit or Delete Payment method  </p>
                            </div>
                        </div>
                        <h6 className="btn custom-btn custom-border-btn custom-border-btn-white " data-bs-toggle="modal" data-bs-target="#payment_method_list" ><i className="ri-add-circle-fill mx-1 ri-xl"></i>Add Payment Method</h6>
                    </div>
                </div>
            </div>

            <div className="container" >

                {bankdetails?.length === 0 && upiDetails?.length === 0 && <div className="favouriteData mt-5">
                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                    <p className="mt-2 mb-4" >Payment Method Not Found </p>
                </div>}
                {
                    bankdetails?.map((item) => {
                        return (
                            <div className="form-field-wrapper bank-acc mb-3  shadow-soft">
                                <div className="acc_details">
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="d-flex align-items-center justify-content-between mb-4 " >
                                                {item?.verified === 0 ?
                                                    <h6 className="badge rounded-pill bg-warning mb-0 p-2">Bank Details Pending</h6>
                                                    : item?.verified === 1 ?
                                                        <h6 className="badge rounded-pill badge-success mb-0 p-2">Bank Details Verified<i className="ri-checkbox-circle-line"></i></h6>
                                                        : item?.verified === 2 ?
                                                            <h6 className="badge rounded-pill badge-danger mb-0 p-2">Bank Details Rejected</h6> : ''
                                                }
                                                <button className=" btn btn-gradient btn-medium justify-content-center" data-bs-toggle="modal" data-bs-target="#add_bank_modal" type="button" onClick={() => AddBankDetails(item)} ><span>Edit Bank</span></button>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start">
                                                <small>Bank Name</small>
                                                <br />{item?.bank_name?.toUpperCase()}
                                            </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start"><small>Account Holder Name</small> <br />{item?.account_holder_name?.toUpperCase()}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start"><small>Account Type</small> <br />{item?.account_type}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start"><small>Account Number</small> <br />{item?.account_number}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start"><small>IFSC Code</small> <br />{item?.ifsc_code}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start"><small>Branch Name</small> <br />{item?.branch_name?.toUpperCase()}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }

                {
                    upiDetails?.map((item) => {
                        return (
                            <div className="form-field-wrapper bank-acc mb-3  shadow-soft">
                                <div className="acc_details">
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="d-flex align-items-center justify-content-between mb-4 " >
                                                {item?.verified === 0 ?
                                                    <h6 className="badge rounded-pill bg-warning mb-0 p-2">UPI Details Pending</h6>
                                                    : item?.verified === 1 ?
                                                        <h6 className="badge rounded-pill badge-success mb-0 p-2 px-3">UPI Details Verified<i className="ri-checkbox-circle-line ms-1"></i></h6>
                                                        : item?.verified === 2 ?
                                                            <h6 className="badge rounded-pill badge-danger mb-0 p-2">UPI Details Rejected</h6> : ''
                                                }

                                                <button className=" btn btn-gradient btn-medium justify-content-center" data-bs-toggle="modal" data-bs-target="#add_upi_modal" type="button" onClick={() => upiDetailsHandle(item)} ><span>Edit UPI</span></button>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-md-4">
                                            <h5 className="text-start">
                                                <small>UPI Id</small>
                                                <br />{item?.upi_id}
                                            </h5>
                                        </div>
                                        <div className="col-md-4">
                                            <img src={ApiConfig?.baseUrl + item?.upi_image} width="100px" alt="scanner" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div className="modal fade" id="add_bank_modal" tabindex="-1" aria-labelledby="add_bank_modalLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Add Account details</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-md-4 custom-form  ">
                            <form >
                                <div className="form-group mb-4 d-inline-block w-100 ">
                                    <label for="royality" className="form-label">Select Bank Type</label>
                                    <select className="form-control mb-0" id="royality" name="accountType" value={accountType} onChange={handleInputChange}>
                                        <option value="SAVING">Saving Account</option>
                                        <option value="CURRENT">Current Account</option>
                                        <option value="FIXED DEPOSIT">Fixed Deposit account</option>
                                    </select>
                                </div>

                                <div className="form-group mb-4 ">
                                    <label for="bit"> Bank Name </label>
                                    <input className="form-control email_input" type="text" id="bit" name="bankName" placeholder="Enter Bank Name" value={bankName} onChange={handleInputChange} />
                                </div>

                                <div className="form-group mb-4">
                                    <label for="bit"> Account Holder Name </label>
                                    <input className="form-control email_input" type="text" name="holderName" placeholder="Enter Account Holder Name " value={holderName} onChange={handleInputChange} />
                                </div>
                                <div className="form-group mb-4">
                                    <label for="bit"> Account Number</label>
                                    <DefaultInput className="form-control email_input" errorStatus={validAccountno(accountNumber)} errorMessage={validAccountno(accountNumber)} id="bit" name="accountNumber" type="number" placeholder="Enter Account Number " value={accountNumber} onChange={handleInputChange} onWheel={(e) => e.target.blur()} />
                                </div>

                                <div className="form-group mb-4">
                                    <label for="bit"> IFSC Code </label>
                                    <DefaultInput className="form-control email_input" errorStatus={validIfscCode(ifscCode)} placeholder="Enter IFSC Code" errorMessage={validIfscCode(ifscCode)} id="bit" name="ifscCode" type="text" value={ifscCode} onChange={handleInputChange} />
                                </div>

                                <div className="form-group mb-4">
                                    <label>Branch Address</label>
                                    <input className="form-control email_input" type="text" placeholder="Enter Branch Address" name="branchAddress" value={branchAddress} onChange={handleInputChange} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer px-8">
                            {!bankId ?
                                <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" disabled={!accountNumber || !ifscCode || !(validAccountno(accountNumber) === undefined) || !(validIfscCode(ifscCode) === undefined)} onClick={() => handleMessageQuery(accountType, bankName, holderName, accountNumber, ifscCode, branchAddress)}><span>Add Bank</span></button>
                                :
                                <button type="button" className="btn btn-gradient btn-medium justify-content-center w-100" disabled={!accountNumber || !ifscCode || !(validAccountno(accountNumber) === undefined) || !(validIfscCode(ifscCode) === undefined)} onClick={() => EditBankDetails(accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, bankId)}><span>Edit Bank</span></button>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="add_upi_modal" tabindex="-1" aria-labelledby="add_bank_modalLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Add UPI details</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-md-4 custom-form  ">
                            <form >

                                <div className="form-group mb-4">
                                    <label for="bit">UPI Id</label>
                                    <input className="form-control email_input" type="text" name="upiId" placeholder="Enter UPI Id" value={upiId} onChange={handleInputChange} />
                                </div>

                                <div className="form-group mb-4 ">
                                    <input name="file" className="form-control email_input" type="file" onChange={(e) => { handleImage(e) }} />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer px-8">
                            {!id ?
                                <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" data-bs-dismiss="modal" aria-label="Close" disabled={!upiImg || !upiId} onClick={() => handleUpi(upiImg, upiId)}><span>Add UPI</span></button>
                                :
                                <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" data-bs-dismiss="modal" aria-label="Close" disabled={!upiImg || !upiId} onClick={() => EditUpiDetails(id, upiImg, upiId)}><span>Edit UPI</span></button>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5">
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Select Payment Method</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-footer p-1">
                            <button type="button" className="btn btn custom-btn custom-border-btn  custom-border-btn-white  justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#add_bank_modal"><span>Bank</span> <i className="ri-add-box-fill "></i></button>
                            <button type="button" className="btn btn custom-btn custom-border-btn  custom-border-btn-white  justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#add_upi_modal"><span>UPI</span> <i className="ri-add-box-fill "></i></button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default PaymentMethod;
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { alertErrorMessage } from "../../../customComponents/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import "swiper/css";
import "swiper/css/pagination";
import AuthService from "../../../api/services/AuthService";
import { SocketContext } from "../../../customComponents/SocketContext";

const Market = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  const [favCoins, setfavCoins] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const [fiterPairData, setFiterPairData] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [trending, setTrending] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const { socket } = useContext(SocketContext);
  const [activeTab, setActiveTab] = useState("Spot");


  useEffect(() => {
    let interval;
    if (socket) {
      let payload = {
        'message': 'market',
      }
      socket.emit('message', payload);
      interval = setInterval(() => {
        let payload = {
          'message': 'market',
        }
        socket.emit('message', payload);
      }, 2000)

      socket.on('message', (data) => {
        setCoinData(data?.pairs);
      });
    }
    return (() => {
      clearInterval(interval)
    })
  }, [socket]);


  useEffect(() => {
    favoriteList();
    getGainloseCoins();
    getTrendingCoins();
  }, []);

  const getGainloseCoins = async () => {
    try {
      const result = await AuthService.get_top_GL()
      if (result) {
        setTopGainers(result?.topGainers);
        setTopLosers(result?.topLosers);
      }
    } catch (error) {
      alertErrorMessage(error?.message)
    }
  }


  const getTrendingCoins = async () => {
    try {
      const result = await AuthService.get_trending()
      if (result) {
        setTrending(result.data);
      }
    } catch (error) {
      alertErrorMessage(error)
    }
  }


  const nextPage = (data) => {
    sessionStorage.setItem('RecentPair', JSON.stringify(data))
    navigate("/trade", { state: data });
  };

  const handleAddFav = async (pairId) => {
    await AuthService.favoriteCoin(pairId).then((result) => {
      if (result.success) {
        try {
          favoriteList();
        } catch (error) {
          alertErrorMessage(result?.message)
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const favoriteList = async () => {
    await AuthService.favoriteList().then((result) => {
      if (result?.success) {
        try {
          setfavCoins(result?.data?.pairs ? result?.data?.pairs : [""]);
        } catch (error) {
          alertErrorMessage(result?.message)
        }
      }
    });
  };

  useEffect(() => {
    let filteredData = coinData?.filter((item) => {
      return (
        item?.base_currency?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.quote_currency?.toLowerCase().includes(search?.toLowerCase())
      );
    });
    setFiterPairData(filteredData);
  }, [search, coinData]);

  const gainerElementRef = useRef(null);


  const handleTabClick = (itemName) => {
    setActiveTab(itemName);
    if (gainerElementRef.current) {
      gainerElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <section className="section-padding login_bg  login_sec  market_page">
      <section className="live_prices mt-0 market_prices market_update_sec market_update_table ">
        <div className="container">
          <div className="row mb-4 g-2">

            {/* Biggest Gainers */}

            <div className="col-lg-4">
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <h3>
                      <span className="bvtPSA me-2"  >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="16px" width="16px" viewBox="0 0 24 24" className="sc-aef7b723-0 fINSSs">
                          <path d="M21 7L12.9 16L7.5 10.6L3 15.1" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M14.6992 7H20.9992V13.3" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                      Biggest Gainers
                    </h3>
                  </div>
                  <button type="button" className="btn btn-link" onClick={() => handleTabClick('Gainers')}> See more &gt;</button>
                </div>

                <div className="ant-card-body">
                  <div className="mt_table" >
                    <table className="table" >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th className="text-end">Price</th>
                          <th className="text-end">24h</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topGainers && topGainers.length <= 0 ?
                          <tr >
                            <td colspan="4" className="p-0" >
                              <div className="loading-wave-market">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                              </div>
                            </td>
                          </tr> :
                          topGainers
                            ? topGainers
                              .filter(item => {
                                const data = item?.quote?.USD;
                                return data?.price > 1 && data?.volume_24h > 2000;
                              })
                              .slice(0, 5).map((item, index) => {
                                const data = item?.quote?.USD;
                                return <tr key={index}>
                                  <td>{item?.num_market_pairs}</td>
                                  <td>
                                    <div className="spotName">
                                      <div className="symblecurrency">
                                        {item?.symbol?.substring(0, 8)}  <span className="text-muted"></span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-end">{parseFloat(data?.price?.toFixed(5))}</td>


                                  <td className={data?.percent_change_24h >= 0 ? "text-success text-end" : "text-danger text-end"} ><b>
                                    <i className={data?.percent_change_24h >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>

                                    {parseFloat(data?.percent_change_24h?.toFixed(5))}%</b>

                                  </td>

                                </tr>
                              })
                            : (
                              <tr >
                                <td colspan="4" className="p-0" >
                                  <div className="favouriteData">
                                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                    <p>No Data Available</p>
                                  </div>
                                </td>
                              </tr>
                            )
                        }

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Biggest Losers */}

            <div className="col-lg-4">
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <h3>
                      <span className="bvtPSA bvtPSAL me-2"  >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="16px" width="16px" viewBox="0 0 24 24" className="sc-aef7b723-0 fINSSs">
                          <path d="M21 17L12.9 8L7.5 13.4L3 8.9" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M14.7002 17.0002H21.0002V10.7002" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                      Biggest Losers
                    </h3>
                  </div>
                  <button type="button" className="btn btn-link" onClick={() => handleTabClick('Losers')}> See more &gt;</button>
                </div>
                <div className="ant-card-body">
                  <div className="mt_table" >
                    <table className="table" >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th className="text-end" >Price</th>
                          <th className="text-end" >24h</th>
                        </tr>
                      </thead>
                      <tbody>

                        {topLosers && topLosers.length <= 0 ?
                          <tr >
                            <td colspan="4" className="p-0" >
                              <div className="loading-wave-market">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                              </div>
                            </td>
                          </tr> :
                          topLosers
                            ? topLosers.slice(0, 5).map((item, index) => {
                              let data = item?.quote?.USD;
                              return (
                                <tr key={index}>
                                  <td>{item?.num_market_pairs}</td>
                                  <td>
                                    <div className="spotName">
                                      <div className="symblecurrency">
                                        {item?.symbol}  <span className="text-muted"></span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-end">{parseFloat(data?.price?.toFixed(5))}</td>


                                  <td className={data?.percent_change_24h >= 0 ? "text-success text-end" : "text-danger text-end"} ><b>
                                    <i className={data?.percent_change_24h >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>

                                    {parseFloat(data?.percent_change_24h?.toFixed(5))}%</b> </td>

                                </tr>
                              );
                            })
                            : (
                              <tr >
                                <td colspan="4" className="p-0" >
                                  <div className="favouriteData">
                                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                    <p>No Data Available</p>
                                  </div>
                                </td>
                              </tr>
                            )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Trending */}
            <div className="col-lg-4">
              <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                    <h3>🔥 Trending</h3>
                  </div>
                  <button type="button" className="btn btn-link" onClick={() => handleTabClick('Trending')}> See more &gt;</button>
                </div>
                <div className="ant-card-body">

                  <div className="mt_table" >
                    <table className="table" >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th className="text-end" >Price</th>
                          <th className="text-end" >24h</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trending && trending.length <= 0 ?
                          <tr >
                            <td colspan="4" className="p-0" >
                              <div className="loading-wave-market">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                              </div>
                            </td>
                          </tr> :
                          trending && trending?.length > 0 ? (
                            trending?.slice(0, 6)?.map((item, index) => {
                              const filterItedddm = coinData?.find(fi => fi.base_currency == item.symbol);
                              const change = filterItedddm ? parseFloat(filterItedddm.change.toFixed(5)) : parseFloat(item.change_24h.toFixed(5));

                              if (item?.symbol === 'USDT') {
                                return null;
                              }
                              return (
                                <tr key={index}>
                                  <td>{item?.num_market_pairs}</td>
                                  <td>
                                    <div className="spotName">
                                      <div className="symblecurrency">
                                        {item?.symbol} <span className="text-muted"></span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-end">{filterItedddm ? parseFloat(filterItedddm.buy_price?.toFixed(5)) : parseFloat(item?.price?.toFixed(5))}</td>
                                  <td className={change >= 0 ? "text-success text-end" : "text-danger text-end"}>
                                    <b>
                                      <i className={change >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>
                                      {change}%
                                    </b>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4" className="p-0">
                                <div className="favouriteData">
                                  <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                  <p>No Data Available</p>
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="container" ref={gainerElementRef}>
          <div className="d-flex-between  mb-3  custom_dlflex">
            <ul className="nav nav-pills mb-2 overflowx_scroll funds_tab  market_tabs">
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "Fav" ? 'active' : ""}`} onClick={() => setActiveTab('Fav')}>
                  <i className="ri-star-s-line me-2 ri-xl"></i> favourite
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "Spot" ? 'active' : ""}`} onClick={() => setActiveTab('Spot')}>
                  <i className="ri-list-unordered ri-xl me-2"></i> Spot
                </button>
              </li>

              <li className="nav-item">
                <button className={`nav-link ${activeTab === "Gainers" ? 'active' : ""}`} onClick={() => setActiveTab('Gainers')}>
                  <i className="ri-arrow-right-up-line me-2"></i>
                  Gainers
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "Losers" ? 'active' : ""}`} onClick={() => setActiveTab('Losers')}>
                  <i className="ri-arrow-right-down-line me-2"></i>
                  Losers
                </button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeTab === "Trending" ? 'active' : ""}`} onClick={() => setActiveTab('Trending')}>
                  <i className="ri-fire-fill me-2"></i> Trending
                </button>
              </li>
            </ul>
            {
              activeTab === "Fav" || activeTab === "Spot" ?
                <div className="searchBar custom-tabs">
                  <i className="ri-search-2-line"></i>
                  <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setsearch(e.target.value); }} />
                </div>
                :
                ""
            }
          </div>
          <div className="tab-content custom-tab-content p-0">


            {/* Favourite */}

            <div className={`tab-pane ${activeTab === "Fav" ? 'active' : ""}`}>
              <div className="card py-2">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    {token ? (
                      fiterPairData && fiterPairData.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Pair</th>
                              <th>Price</th>
                              <th>24H Change</th>
                              <th>24H High</th>
                              <th>24H Low</th>
                              <th>24H Vol</th>
                              <th>24H Turnover</th>
                              <th>Operation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fiterPairData && fiterPairData.map((item, index) => {
                              if (!favCoins.includes(item?._id)) return null;
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="td_div">
                                      <span className="star_btn btn_icon active">
                                        <i className="ri-star-fill text-warning me-2" onClick={() => { handleAddFav(item?._id); }}></i>
                                      </span>
                                      <img alt="" src={ApiConfig.baseUrl + item?.icon_path} className="img-fluid icon_img coinimg me-2" />
                                      {item?.base_currency}/{item?.quote_currency}
                                    </div>
                                  </td>
                                  <td><b>{parseFloat(item?.buy_price?.toFixed(5))}</b></td>
                                  <td className={item?.change >= 0 ? "color-green text-green" : "color-red text-danger"}><b>{parseFloat(item?.change?.toFixed(5))}</b> </td>
                                  <td><b>{parseFloat(item?.high?.toFixed(5))}</b></td>
                                  <td><b>{parseFloat(item?.low?.toFixed(5))}</b></td>
                                  <td><b>{parseFloat(item?.volume?.toFixed(5))}</b></td>
                                  <td><b>{parseFloat((item?.buy_price * item?.volume)?.toFixed(5))}</b></td>
                                  <td><a href="javascript:void(0)" onClick={() => nextPage(item)} className="btn custom-btn btn-sm"><span>Trade</span></a></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="favouriteData">
                          <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                          <p>No Data Available</p>
                        </div>
                      )
                    ) : (
                      <div className="py-5 favouriteData">
                        <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                        <p className="mt-2">No results.... Go to&nbsp;
                          <Link className="btn-link" to="/login"><b>&nbsp; Sign in &nbsp;</b></Link>&nbsp;and add your favorite coins from Spot.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Spot */}

            <div className={`tab-pane ${activeTab === "Spot" ? 'active' : ""}`}>
              <div className="card  py-2">
                <div className="card-body p-0">
                  <div className="mrt_row">
                  </div>
                  <div className="table-responsive">
                    {fiterPairData?.length > 0 ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th> Pair</th>
                            <th> Price</th>
                            <th> 24H Change</th>
                            <th> 24H High</th>
                            <th> 24H Low</th>
                            <th> 24H Vol</th>
                            <th> 24H Turnover</th>
                            <th> Operation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fiterPairData ? fiterPairData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="td_div">
                                  {token && (
                                    <span className={"star_btn btn_icon active"}>
                                      <i className={favCoins.includes(item?._id) ? "ri ri-star-fill text-warning me-2 " : "ri ri-star-line me-2 "} onClick={() => { handleAddFav(item?._id); }} ></i>
                                    </span>
                                  )}
                                  <img alt="" src={ApiConfig.baseUrl + item?.icon_path} className="img-fluid icon_img coinimg me-2 " />
                                  {item?.base_currency}/
                                  {item?.quote_currency}
                                </div>
                              </td>
                              <td><b>{parseFloat(item?.buy_price?.toFixed(5))}</b> </td>
                              <td className={item?.change >= 0 ? "color-green text-green" : "color-red text-danger"} ><b>{parseFloat(item?.change?.toFixed(5))}</b></td>
                              <td><b>{parseFloat(item?.high?.toFixed(5))}</b></td>
                              <td><b>{parseFloat(item?.low?.toFixed(5))}</b></td>
                              <td><b>{parseFloat(item?.volume?.toFixed(5))}</b></td>
                              <td><b>{parseFloat((item?.buy_price * item?.volume)?.toFixed(5))}</b> </td>
                              <td> <span onClick={() => nextPage(item)} className="btn custom-btn btn-sm cursor-pointer"><span>Trade</span></span></td>
                            </tr>
                          ))
                            : null
                          }
                        </tbody>
                      </table>
                    ) : (
                      <div className="favouriteData">
                        <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                        <p>No Data Available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>


            {/* Biggest Gainers */}

            <div className={`tab-pane ${activeTab === "Gainers" ? 'active' : ""}`}>
              <div className="card  py-2">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    {
                      topGainers && topGainers?.length > 0 ? (
                        <>
                          {/* <h5 className="text-center">Biggest Gainers</h5> */}
                          <table className="table ">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th> Name</th>
                                <th> Price</th>
                                <th> 24H </th>
                                <th> Volume(24H)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topGainers
                                ? topGainers
                                  .filter(item => {
                                    const data = item?.quote?.USD;
                                    return data?.price > 1 && data?.volume_24h > 2000;
                                  })
                                  .map((item, index) => {
                                    const data = item?.quote?.USD;
                                    return <tr key={index} >
                                      <td className="">
                                        {item?.num_market_pairs}
                                      </td>
                                      <td>
                                        <div className="td_div">
                                          {item?.name}
                                          <small>&nbsp; | {item?.symbol}</small>
                                        </div>
                                      </td>
                                      <td><b>{parseFloat(data?.price?.toFixed(5))}</b></td>


                                      <td className={data?.percent_change_24h >= 0 ? "color-green text-green" : "color-red text-danger"} ><b>
                                        <i className={data?.percent_change_24h >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>

                                        {parseFloat(data?.percent_change_24h?.toFixed(5))}%</b> </td>
                                      <td> <b> {parseFloat((data?.volume_24h)?.toFixed(5))} </b> </td>
                                    </tr>
                                  })
                                : null
                              }
                            </tbody>
                          </table> </>
                      ) : (
                        <div className="favouriteData">
                          <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                          <p>No Data Available</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* Biggest Losers */}

            <div className={`tab-pane ${activeTab === "Losers" ? 'active' : ""}`}>
              <div className="card  py-2">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    {
                      topLosers && topLosers?.length > 0 ? (
                        <>
                          {/* <h5 className="text-center">Biggest Losers</h5> */}
                          <table className="table ">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th> Name</th>
                                <th> Price</th>
                                <th> 24H </th>
                                <th> Volume(24H)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topLosers
                                ? topLosers.map((item, index) => {
                                  let data = item?.quote?.USD;
                                  return <tr key={index} ca>
                                    <td className="">
                                      {item?.num_market_pairs}
                                    </td>
                                    <td>
                                      <div className="td_div">
                                        {/* <img alt="" src={ApiConfig.baseUrl + item?.icon_path} className="img-fluid icon_img coinimg me-2 " /> */}
                                        {item?.name}
                                        <small>&nbsp; | {item?.symbol}</small>
                                      </div>
                                    </td>
                                    <td><b>{parseFloat(data?.price?.toFixed(5))}</b></td>

                                    <td className={data?.percent_change_24h >= 0 ? "color-green text-green" : "color-red text-danger"} ><b>
                                      <i className={data?.percent_change_24h >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>

                                      {parseFloat(data?.percent_change_24h?.toFixed(5))}%</b> </td>

                                    <td> <b> {parseFloat((data?.volume_24h)?.toFixed(5))} </b> </td>
                                  </tr>
                                })
                                : null
                              }
                            </tbody>
                          </table> </>
                      ) : (
                        <div className="favouriteData">
                          <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                          <p>No Data Available</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* Trending */}

            <div className={`tab-pane ${activeTab === "Trending" ? 'active' : ""}`}>
              <div className="card  py-2">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    {
                      trending && trending?.length > 0 ? (
                        <>
                          {/* <h5 className="text-center"> Top Trending</h5> */}
                          <table className="table ">
                            <thead>
                              <tr>
                                <th> #</th>
                                <th> Name</th>
                                <th> Price</th>
                                <th> 24H </th>
                                <th>	Volume(24H)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {trending.map((item, index) => {
                                const filterItem = coinData?.find(fi => fi.base_currency === item.symbol);
                                if (item.symbol === 'USDT') return null;
                                const change = filterItem ? parseFloat(filterItem.change.toFixed(5)) : parseFloat(item.change_24h.toFixed(5));
                                const volume = filterItem ? parseFloat(filterItem.volume.toFixed(5)) : parseFloat(item.total_supply.toFixed(5));

                                
                                return (
                                  <tr key={index}>
                                    <td className="">{item.num_market_pairs}</td>
                                    <td>
                                      <div className="td_div">
                                        <div className="coin_name">{item.symbol}</div>
                                      </div>
                                    </td>
                                    <td><b>{filterItem ? parseFloat(filterItem.buy_price?.toFixed(5)) : parseFloat(item?.price?.toFixed(5))}</b></td>
                                    <td className={change >= 0 ? "color-green text-green" : "color-red text-danger"}>
                                      <b>
                                        <i className={change >= 0 ? "ri-arrow-up-s-fill me-1" : "ri-arrow-down-s-fill me-1"}></i>
                                        {parseFloat(change.toFixed(5))}%
                                      </b>
                                    </td>
                                    <td><b>{volume}</b></td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <div className="favouriteData">
                          <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                          <p>No Data Available</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </section >
  );
};

export default Market;

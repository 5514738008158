import { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import { ProfileContext } from '../../../context/ProfileProvider';


export default function TVChartContainer({ symbol }) {
  const { newStoredTheme } = useContext(ProfileContext);
  const [tvWidget, setTvWidget] = useState();
  const functCheckRef = useRef(true);
  const Theme = localStorage.getItem('theme');

  const getChart = (symbol) => {
    const widgetOptions = {
      symbol: `${symbol}`,
      interval: '1',
      fullscreen: false,
      timezone: 'Asia/Kolkata',
      container: "TVChartContainer",
      datafeed: Datafeed,
      has_intraday: true,
      library_path: '/charting_library/',
      pricescale: 100000000,
      intraday_multipliers: ['1', '60'],
      custom_css_url: 'css/style.css',
      height: '467px',
      logo: {
        image: '/favicon.png',
        link: "https://cvtrade.io/"
      },
      time_frames: [
        { text: '1D', resolution: 'D', description: '1 Day' },
        { text: '1W', resolution: 'W', description: '1 Week' },
        { text: '1M', resolution: 'M', description: '1 Month' },
      ],
      theme: Theme ? Theme : 'dark',
      overrides: {
        "symbolInfo.priceFormatter": {
          precision: 4
        },
        "paneProperties.background": Theme === 'light' ? "#ffffff" : '#1c1c2e',
        "paneProperties.backgroundType": "solid",
      },
      loading_screen: {
        backgroundColor:  Theme === 'light' ? "#ffffff" : '#1c1c2e',
      },
      time_scale: {
        min_bar_spacing: 10
      },
      // Disable default chart features and enable only required ones
      disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart", 'header_symbol_search', 'header_interval_dialog_button', 'header_compare', 'header_undo_redo', 'header_resolutions'],
    };

    const tvWidgetInstance = new widget(widgetOptions);
    setTvWidget(tvWidgetInstance);

    // Set chart to Heikin Ashi after widget is fully loaded
    tvWidgetInstance.onChartReady(() => {
      const chart = tvWidgetInstance.chart();

      if (chart) {
        // Set Heikin Ashi as the default chart type
        chart.setChartType(8);  // 3 should be the Heikin Ashi chart type
      }
    });
  };

  useEffect(() => {
    if (symbol.split('/')[0] !== 'undefined') {
      if (functCheckRef.current) {
        getChart(symbol);
      }
      functCheckRef.current = false;
    };
  }, [symbol]);

  useEffect(() => {
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        const chart = tvWidget.chart();
        if (chart) {
          chart.setSymbol(symbol, () => null);
        }
      });
    }
  }, [symbol]);

  useEffect(() => {
    getChart(symbol);
  }, [newStoredTheme, Theme]);

  useEffect(() => {
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        const chart = tvWidget.chart();
        if (chart) {
          tvWidget.headerReady().then(function () {
            const intervals = [
              { value: '1', label: '1 Min' },
              { value: '5', label: '5 Min' },
              { value: '15', label: '15 Min' },
              { value: '30', label: '30 Min' },
              { value: '60', label: '1 Hour' },
              { value: 'D', label: '1 Day' },
              { value: 'W', label: '1 Week' },
              { value: 'M', label: '1 Month' }
            ];
            intervals.forEach(interval => {
              const button = tvWidget.createButton();
              button.classList.add('custom-interval-button');
              button.title = `Switch to ${interval.label}`;
              button.addEventListener('click', function () {
                tvWidget.chart().setResolution(interval.value);
              });
              button.textContent = interval.label;
            });
          });
        }
      });
    }
  }, [tvWidget]);

  return (
    <div id="TVChartContainer" />
  );
}  
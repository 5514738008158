import React from "react";
import { Link } from "react-router-dom";

const FeesPage = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> Fee Structure </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Fee Structure</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="row align-items-center py-5">
                  <div className="col-lg-12 ">
                      <p className="title  mb-2"><h3>CVtrade:</h3>  Offers tiered maker-taker fees, starting at 0.1% for makers and 0.1% for takers. Discounts are available for holding CVT, their native token. </p>
                    </div>
                </div>
                <div className="row align-items-center pb-5">
                  <div className="col-lg-12">
                    <div>
                      <h3 className="title  mb-3"> Understanding Fee Types: </h3>
                      <p>• Maker Fees: Paid by users who add liquidity to the market by placing limit orders.</p>
                      <p>• Taker Fees: Paid by users who remove liquidity from the market by placing market orders.</p>
                      <p>• Spread: The difference between the buying and selling price of an asset on an exchange.</p>
                      <p>• Deposit/Withdrawal Fees: Some exchanges charge fees for depositing or withdrawing fiat currency or cryptocurrency.</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row align-items-center pb-5">
                  <div className="col-lg-12">
                    <div>
                      <h3 className="title  mb-3">Additional Fees: </h3>
                      <p>CVtrade charges a flat 0.1% maker fee and 0.1% taker fee for all trades, as well as a withdrawal fee of 0.001 BTC for all withdrawals. This fee structure is intended to encourage market makers, who add liquidity to the market. </p>
                      <p>CVtrade is a blockchain-based banking platform for crypto traders and investors, and aims to connect the world of traditional finance and cryptocurrencies.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </section>
  );
}

export default FeesPage;
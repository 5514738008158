import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SignupPage from "../../src/ui/Pages/SignupPage";
import UserHeader from "../customComponents/UserHeader";
import Footer from "../customComponents/Footer";
import LandingPage from "../ui/Pages/LandingPage";
import LoginPage from "../ui/Pages/LoginPage";
import ForgotPassword from "../ui/Pages/ForgotPassword";
import KycPage from "../ui/Pages/KycPage";
import ProfilePage from "../ui/Pages/ProfilePage";
import ComingSoonPage from "../ui/Pages/ComingSoonPage";
import AuthHeader from "../customComponents/AuthHeader";
import Trade from "../ui/Pages/TradePage";
import Market from "../ui/Pages/Market";
import Funds from "../ui/Pages/Funds";
import NotificationPage from "../ui/Pages/NotificationPage";
import { ProfileContext } from "../context/ProfileProvider";
import PrivacyPolicy from "../ui/Pages/PrivacyPolicy";
import TermsCondition from "../ui/Pages/TermsCondition";
import Termsofuse from "../ui/Pages/Termsofuse";
import AmlPolicy from "../ui/Pages/AmlPolicy";
import Contactus from "../ui/Pages/Contactus";
import FeesPage from "../ui/Pages/FeesPage";
import ListCoin from "../ui/Pages/ListCoin";
import ReferalPage from "../ui/Pages/ReferalPage";
import SwapLaunchpad from "../ui/Pages/SwapLaunchpad";
import Launchpad from "../ui/Pages/Launchpad";
import TokenPage from "../ui/Pages/TokenPage";
import PartnershipPage from "../ui/Pages/PartnershipPage";
import RefundCancellation from "../ui/Pages/RefundCancellation";
import SecuritySystem from "../ui/Pages/SecuritySystem";
import RefrealList from "../ui/Pages/RefrealList";
import BlogList from "../ui/Pages/Blog/BlogList";
import MobileChart from "../ui/Pages/MobileChart";
import PTwoP from "../ui/Pages/PTwoP";
import NewPost from "../ui/Pages/PTwoP/NewPost";
import MyPost from "../ui/Pages/PTwoP/MyPost";
import MyOrders from "../ui/Pages/PTwoP/MyOrders";
import BuyPTwop from "../ui/Pages/PTwoP/BuyPTwop";
import Messenger from "../utils/Chat/Messenger";
import PaymentMethod from "../ui/Pages/PTwoP/PaymentMethod";
import BuySell from "../ui/Pages/QuickBuySell";

function Routing() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { userDetails } = useContext(ProfileContext);
  const isChartPage = location?.pathname?.includes('/chart');

  return (
    <>
      {isChartPage ? null : token ? <AuthHeader userDetails={userDetails} /> : <UserHeader />}
      <Routes>
        <Route exact path="/" element={<LandingPage />} />

        <Route path="/user_profile" element={token ? (<ProfilePage userDetails={userDetails} />) : (<Navigate to="/login" replace />)} />
        <Route path="/kyc" element={token ? (<KycPage userDetails={userDetails} />) : (<Navigate to="/login" replace />)} />
        <Route path="/funds_details" element={token ? (<Funds userDetails={userDetails} />) : (<Navigate to="/login" replace />)} />
        <Route path="/swapLaunchpad" element={token ? <SwapLaunchpad /> : <Navigate to="/login" replace />} />
        <Route path="/launchpad" element={token ? <Launchpad /> : <Navigate to="/login" replace />} />
        <Route path="/referal_list" element={token ? <RefrealList /> : <Navigate to="/login" replace />} />
        <Route path="/quick_buy_sell" element={token ? <BuySell /> : <Navigate to="/login" replace />} />
        
        {/* P2P Routes */}
        <Route exact path="/p2p_trading" element={token ? <PTwoP /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/add_post" element={token ? <NewPost /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/MyPost" element={token ? <MyPost /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/my_orders" element={token ? <MyOrders /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/buyPTwop_details/:orderId" element={token ? <BuyPTwop /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/messanger" element={token ? <Messenger /> : <Navigate to="/login" replace />}></Route>
        <Route exact path="/PaymentMethod" element={token ? <PaymentMethod /> : <Navigate to="/login" replace />}></Route>


        <Route exact path="/forgot_password" element={<ForgotPassword />} />
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/market" element={<Market />} />
        <Route exact path="/trade" element={<Trade />} />
        <Route exact path="/notification" element={<NotificationPage />} />
        <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms_conditions" element={<TermsCondition />} />
        <Route exact path="/terms_use" element={<Termsofuse />} />
        <Route exact path="/aml_policy" element={<AmlPolicy />} />
        <Route exact path="/refund_cancellation" element={<RefundCancellation />} />
        <Route exact path="/contactus" element={<Contactus />} />
        <Route exact path="/fees" element={<FeesPage />} />
        <Route exact path="/coin_list" element={<ListCoin />} />
        <Route exact path="/token" element={<TokenPage />} />
        <Route exact path="/partnership" element={<PartnershipPage />} />
        <Route exact path="/refrefer_earn" element={<ReferalPage />} />
        <Route exact path="/security_system" element={<SecuritySystem />} />
        <Route exact path="/blogs" element={<BlogList />} />
        <Route exact path="" element={<SwapLaunchpad />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/comingsoon" element={<ComingSoonPage />} />
        <Route exact path="/chart/:pairs" element={<MobileChart />}></Route>
        <Route path="/*" element={<ComingSoonPage />} />
      </Routes>
      {!isChartPage ? <Footer /> : null}
    </>
  );
}

export default Routing;

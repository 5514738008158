import React from 'react';

const MessageSend = ({ inputHendle, newMessage, sendMessage, emojiSend, ImageSend, orderStatus }) => {

     const emojis = [
          '😀', '😃', '😄', '😁',
          '😆', '😅', '😂', '🤣',
          '😊', '😇', '🙂', '🙃',
          '😉', '😌', '😍', '😝',
          '😜', '🧐', '🤓', '😎',
          '😕', '🤑', '🥴', '😱'
     ];

     return (
          <div className="chat-footer send-message">
               <form onSubmit={sendMessage}>
                    <input type="text" onChange={inputHendle} cols="10" rows="1" className="form-control" value={newMessage} placeholder="Type your message here ..." />
                    <span className="chat_action">
                         {orderStatus === "CANCELLED" ?
                              <><a href="#/" id="attach" className="attach_icon"> <input type="file" disabled /><i className="ri-attachment-2"></i> </a>
                                   <a href="#/" id="attach" className="attach_icon icon_send" disabled><i className="ri-send-plane-fill" ></i></a></> :

                              <><a href="#/" id="attach" className="attach_icon"> <input type="file" onChange={ImageSend} /><i className="ri-attachment-2"></i> </a>
                                   <a href="#/" id="attach" className="attach_icon icon_send" onClick={sendMessage}><i className="ri-send-plane-fill" ></i></a></>}
                    </span>
               </form>
               <div className='emoji-section'>
                    <div className='emoji'>{emojis.map(e => <span onClick={() => emojiSend(e)} >{e}</span>)}</div>
               </div>
          </div>

     )
};

export default MessageSend;

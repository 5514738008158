import React, { createContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client';
import { ApiConfig } from '../../api/apiConfig/apiConfig';

export const SocketContext = createContext();
const SocketContextProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    
    useEffect(() => {

        const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
            transports: ['websocket'],
            upgrade: false,
            rejectUnauthorized: false,
            reconnectionAttempts: 3,
            debug: true
        });

        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }, []);





    var settings = {
      centerMode: true,
      centerPadding: '60px',
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 600,
      autoplaySpeed: 2000,
      arrows: false,
      slidesToShow: 5,
      slidesToScroll: 'auto',
      responsive: [
        {
          breakpoint: 768,
          settings: {          
          centerPadding: '2px',
            slidesToShow: 1,
            slidesToScroll: 'auto',
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 'auto',
          },
        },
      ],
    };


    var settingstwo = {  
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 600,
      autoplaySpeed: 2000,
      arrows: false,
      slidesToShow: 3,  
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
  
   
  
    var Sliderpartners = {
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 600,
      autoplaySpeed: 2000,
      arrows: false, 
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
  
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    var Sliderpartners2 = {
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      arrows: false,
      speed: 8000,
      pauseOnHover: false,
      cssEase: "linear",
      rtl: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
  
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };


    return (
        <SocketContext.Provider value={{ socket,settings,Sliderpartners,Sliderpartners2, settingstwo }}> {children}</SocketContext.Provider>
    )
}

export default SocketContextProvider

import React from "react";
import { Link } from "react-router-dom";

const Contactus = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> Help Center </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Contactus</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row " >
            <div className="col-md-12 m-auto" >
              <div className="create-item-wrapper plicy_sec career_sec">
                <div className="row align-items-center gx-md-5">
                  <div className="col-lg-7 ">
                    <div>
                      <h2 className="title mb-3"> Contact with us </h2>
                      <p>  Need help with CV Trade? Visit our Support Center to get in touch with our dedicated support team, available 24/7. For other inquiries, connect with us below. </p>
                      
                      <div className="cc_row" >
                          <div className="cc_col" >
                           <b> Press Inquiries</b>   <a href="mailto:info@cvtrade.io" target="_blank" >info@cvtrade.io</a>
                          </div>
                          <hr/>
                          <div className="cc_col" >
                           <b> Compliance Inquiries  </b>   <a href="mailto:compliance@cvtrade.io" target="_blank" >compliance@cvtrade.io</a>
                          </div>

                          <hr/>
                          <div className="cc_col" >
                           <b> Support Inquiries</b>   <a href="mailto:support.@cvtrade.io" target="_blank" >support.@cvtrade.io</a>
                          </div>
                      </div>
                      {/* <h4 className="font-weight-normal" > <a href="mailto:support.@cvtrade.io" target="_blank" >support.@cvtrade.io</a></h4> */}



                    </div>
                  </div>
                  <div className="col-lg-5">
                    <img src="/images/contact_img.svg" className="img-fluid career_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Contactus;
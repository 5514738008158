import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { ProfileContext } from "../../../context/ProfileProvider";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponents/CustomAlertMessage";

const NotificationPage = () => {

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    handleNotifications();
  }, [])

  const handleNotifications = async () => {
    const result = await AuthService.notifications()
      if (result.success) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {
           alertErrorMessage(result?.message)
        }
      }
      else {
        alertErrorMessage(result.message)
      }
    }

    
  return (
    <>
      <div className="profile_sections" >
        <div className="container" >
          <div className="row" >
            <div className="col-md-6" >
              <h2 className="mb-0 pb-0">All Notifications</h2>
            </div>
            <div className="col-md-6" >
              <nav className=" ">
                <ol className="breadcrumb justify-content-end">
                  <li className="breadcrumb-item"><Link to="/user_profile">My Account</Link></li>
                  <li className="breadcrumb-item active" aria-current="page"> Notification</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="card twofa_card">
                <div className="card-body" >
                  <div className="card-body_inner" >
                    <div className="notifications__list">
                      {notifications?.length > 0 ? (
                        notifications?.map((value, index) => (
                          <div className="notifications__item" key={index}>
                            <div className="notifications__details">
                              <div className="notifications__line">
                                <div className="notifications__subtitle">{value?.title}</div>
                                <div className="notifications__date">
                                  {moment(value?.createdAt).format(
                                    "MMMM Do YYYY, h:mm:ss A"
                                  )}
                                </div>
                              </div>
                              <div className="notifications__content"> {value?.message}  </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="py-5 py-5 text-center" >
                          <p className="mb-0 text-center" >No Notifications Available</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotificationPage;

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import Header from "./Header";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../../customComponents/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ProfileContext } from "../../../context/ProfileProvider";

const NewPost = () => {
  const navigate = useNavigate();
  const [coinList, setCoinList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState([]);
  const [tAndC, setTAndC] = useState(false);
  const [inputData, setInputData] = useState({ asset: '', maxAmount: '', minAmount: '', price: '', priceType: 'Limit', fiat: '', remarks: '', side: 'BUY', time: '5', kyc: false, registered: false, regDays: '', transaction: '', minCurrency: '', currencyAmount: '' });
  const [paymentMethod, setpaymentMethod] = useState([]);
  const [upiList, setUpiList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [coins, setCoins] = useState([])
  const [type, setType] = useState()
  const paymentList = ["UPI", "BANK"]
  const { userDetails } = useContext(ProfileContext);
  const KycStatus = userDetails?.kycVerified;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fiat' || name === 'asset') {
      setInputData({ ...inputData, priceType: 'Limit' });
    }
    if (name === 'priceType' && value === 'Fixed') {
      if (!inputData?.asset || !inputData?.fiat) {
        alertWarningMessage('Please Select Asset and Fiat currency');
        setInputData({ ...inputData, priceType: 'Limit' });
        return
      } else {
        currentPrice()
        setInputData({ ...inputData, priceType: 'Fixed' });
      }
    }
    setInputData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const p2pCoinList = async () => {
    await AuthService.p2pCoinList().then(async (result) => {
      if (result?.success) {
        setCoinList(result?.data)
      }
    });
  };

  const fiatCurrencyList = async () => {
    await AuthService.fiatCurrencyList().then(async (result) => {
      if (result?.success) {
        setFiatCurrency(result?.data)
      }
    });
  };

  const currentPrice = async () => {
    await AuthService.currentPrice(inputData?.fiat, inputData?.asset).then(async (result) => {
      if (result?.success) {
        if (inputData?.side === 'BUY') {
          setInputData({ ...inputData, price: result?.data?.buy_price, priceType: 'Fixed' });
        } else {
          setInputData({ ...inputData, price: result?.data?.sell_price, priceType: 'Fixed' });
        }
      } else {
        setInputData({ ...inputData, priceType: 'Limit' });
        alertErrorMessage(result?.message)
      }
    });
  };

  const handleCoinList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCoinList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoins(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const createAd = async () => {
    if (!tAndC || !inputData?.asset || !inputData?.fiat || !inputData?.price || !inputData?.priceType || !inputData?.side || !inputData?.time || paymentMethod?.length === 0) {
      alertWarningMessage('Please fill all required details');
      return
    };
    try {
      LoaderHelper?.loaderStatus(true);
      const result = await AuthService.createNewPost(inputData, paymentMethod)
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setInputData({ asset: '', maxAmount: '', minAmount: '', price: '', priceType: 'Limit', fiat: '', remarks: '', side: 'BUY', time: '5', kyc: false, registered: false, regDays: '', transaction: '', minCurrency: '', currencyAmount: '' });
        setTAndC(false)
        setpaymentMethod([])
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        alertErrorMessage(result?.message)
      }
    } catch (error) { alertErrorMessage(error.message) }
    finally { LoaderHelper?.loaderStatus(false) }
  };

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setpaymentMethod([item]);
    }
  };

  const fetchPaymentMethods = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.fetchPaymentMethods().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        let verifiedBank = result?.data?.BankDetails?.filter((item) => item?.verified == 1)
        let verifiedUPI = result?.data?.UpiDetails?.filter((item) => item?.verified == 1)
        setUpiList(verifiedUPI)
        setBankList(verifiedBank)
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    p2pCoinList()
    fiatCurrencyList()
    fetchPaymentMethods()
    handleCoinList()
  }, []);

  return (
    <>
      <Header />
      <div className="buy_bar " >
        <div className="container" >
          <div className="bar_row" >
            <div className="bar_left" >
              <div className="bar_title" >
                <h3> Create New Post  </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bar_data" >
        <div className="container" >
          <div className="row" >
            <div className="col-lg-7" >
              <form onSubmit={(e) => { e.preventDefault(); createAd() }}>
                <div className="post_data" >

                  <div className="post_box" >
                    <h5> <img src="images/nav_arrow.svg" width="10" className="" alt="" /> I want to {inputData?.side} </h5>
                    <div className="row" >
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Asset</label>
                          <select className="form-select form-control form-control email_input" required name="asset" onChange={handleInputChange} value={inputData?.asset}>
                            <option value='' disabled selected>Select Asset</option>
                            {coinList ? coinList?.map((item, index) => {
                              return (
                                <option key={index}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Fiat Currency</label>
                          <select className="form-select form-control form-control email_input" required name="fiat" onChange={handleInputChange} value={inputData?.fiat} >
                            <option value='' disabled selected>Select Fiat Currency</option>
                            {fiatCurrency ? fiatCurrency?.map((item, index) => {
                              return (
                                <option key={item?.id}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Side</label>
                          <select className="form-select form-control form-control email_input" required name="side" onChange={(e) => { handleInputChange(e); setpaymentMethod([]) }} value={inputData?.side}>
                            <option value='BUY'>Buy</option>
                            <option value='SELL'>Sell</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="pay_warning pay_success">  Fee: 0% </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Price Settings </h5>
                    <div className="row" >
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Price Type</label>
                          <select className="form-select form-control form-control email_input" name="priceType" required onChange={handleInputChange} value={inputData?.priceType}>
                            <option value='Limit'>Limit</option>
                            <option value='Fixed'>Fixed</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label> Price</label>
                          <input className="form-control email_input" required type="number" placeholder="Enter Price" onWheel={(e) => e.target.blur()} name="price" onChange={handleInputChange} disabled={inputData?.priceType === 'Fixed'} value={inputData?.price} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Quantity</h5>
                    <div className="row" >
                      <div className="col-12" >
                        <div className="form-group" >
                          <label>Quantity</label>
                          <input className="form-control email_input" id="volumee" required type="number" placeholder="Enter Quantity" onWheel={(e) => e.target.blur()} name="volume" onChange={handleInputChange} value={inputData?.volume} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Time</h5>
                    <div className="row" >
                      <div className="col-12" >
                        <div className="form-group" >
                          <label>Time Period</label>
                          <select className="form-select form-control form-control email_input" required name="time" onChange={handleInputChange} value={inputData?.time}>
                            <option value='5'>5 minutes</option>
                            <option value='10'>10 minutes</option>
                            <option value='15'>15 minutes</option>
                            <option value='30'>30 minutes</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" alt="" />  Select Payment Method </h5>

                    <div className="pay_radio pay_select">
                      <div className="row">
                        {(upiList.length > 0 || bankList.length > 0) &&
                          <div className=" col-md-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#payment_method_list">
                            {
                              paymentMethod.length > 0 ? paymentMethod.map(item => item.type == "UPI" ?
                                <div className="form-check form-checkd my-0" >
                                  {item?.upi_id}
                                  <i class="ri-checkbox-circle-fill text-gradient  me-2"></i>
                                </div>
                                :
                                <div className="form-check form-checkd  my-0" >
                                  {item?.bank_name}
                                  <i class="ri-checkbox-circle-fill text-gradient  me-2"></i>
                                </div>
                              ) :
                                <div className="form-check form-checkd  my-0" >
                                  Select Payment Method
                                  <i class="ri-add-line ri-xl text-gradient  me-2"></i>
                                </div>
                            }
                          </div>
                        }
                        <div className="col-md-6" >
                          <button className="custom-btn custom-border-btn  custom-border-btn-white" type="button" onClick={() => navigate("/PaymentMethod")}>
                            Add New  Method
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />Currency Holdings </h5>
                    <div className="row" >
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Currency</label>
                          <select className="form-select form-control form-control email_input" name="minCurrency" onChange={handleInputChange} value={inputData?.minCurrency} >
                            <option value='' disabled selected>Select Currency</option>
                            {coins ? coins?.map((item, index) => {
                              return (
                                <option key={item?._id}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group">
                          <label>Enter Amount</label>
                          <input className="form-control email_input" type="number" placeholder="0" name="currencyAmount" disabled={!inputData?.minCurrency} onWheel={(e) => e.target.blur()} onChange={handleInputChange} value={inputData?.currencyAmount} />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="post_box mb-1" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Counterparty Conditions</h5>
                    <div className="pay_radio pay_select " >
                      <div className="row" >
                        <div className="col-md-6" >
                          <div className="form-check ">
                            <input className="form-check-input" type="checkbox" id="Completed" checked={inputData?.kyc} onChange={(e) => setInputData({ ...inputData, kyc: e.target.checked })} />
                            <label className="form-check-label" htmlFor="Completed">
                              Completed KYC
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6" >
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="Registered" checked={inputData?.registered} onChange={(e) => { setInputData({ ...inputData, registered: e.target.checked, regDays: !e.target.checked ? '' : inputData?.regDays }); }} />
                            <label className="form-check-label" htmlFor="Registered">
                              Registered
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12" >
                          <div className="form-group " >
                            <label> Register day(s) ago</label>
                            <input className="form-control email_input " type="text" placeholder="0" name="regDays" disabled={!inputData?.registered} onChange={handleInputChange} value={inputData?.regDays} />
                          </div>
                        </div>
                        <div className="col-md-12" >
                          <div className="form-group " >
                            <label>Number of transactions successfully completed</label>
                            <input className="form-control email_input " type="number" placeholder="0" name="transaction" onWheel={(e) => e.target.blur()} onChange={handleInputChange} value={inputData?.transaction} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="advertiseList_tradingAgreement__5tQwy">
                    <div className="form-check mb-4">
                      <input className="form-check-input" type="checkbox" required id="flexCheckDefault" onChange={(e) => setTAndC(e.target.checked ? true : false)} />
                      I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement</div>
                  </div>

                  <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                    <div className="col-6 col-md-4"><button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white w-100   "><span>Cancel</span></button></div>
                    <div className="col-6  col-md-8">
                      {KycStatus != "2" ?
                        <Link className="btn custom-btn btn-success btn-block w-100 btn-shadow-soft " to='/kyc'>Verify KYC</Link> : <button type="submit" className="btn custom-btn btn-success btn-block w-100 btn-shadow-soft "  >Create Ad </button>}

                    </div>
                  </div>

                </div>
              </form>
            </div>

            {/* Buy\Sell Card */}
            <div className="col-lg-5" >
              <div className="side_sticky d-none d-md-block" >
                <div className="post_prev chat_box card" >
                  <div className="prev_head chat-header" >
                    <h4 className="mb-0" >Posting <b className={inputData?.side === "BUY" ? "text-success" : "text-danger"} >{inputData?.side} {inputData?.asset} </b>  </h4>
                  </div>
                  <ul className="prev_body" >
                    <li>
                      <div>
                        Price
                      </div>
                      <strong>{(inputData?.asset || inputData?.price || inputData?.fiat) && `${inputData?.asset} / ${inputData?.price} `} <small>{inputData?.fiat}</small> </strong>
                    </li>
                    <li>
                      <div>
                        Limits
                      </div>
                      <strong><small>{(inputData?.minAmount || inputData?.maxAmount) && `${inputData?.minAmount} - ${inputData?.maxAmount} ${inputData?.fiat}`} </small> </strong>
                    </li>
                    <li>
                      <div>
                        Payment Method
                      </div>
                      <div className="advertiseList_paymentContent__1oElX justify-content-end">
                        {paymentMethod?.map((item, index) =>
                          <span key={index} className={`advertiseList_paymentList__IageJ ${index % 3 === 0 ? 'badge-danger' : index % 3 === 1 ? 'badge-primary' : 'badge-success'}`}><span><span>{item.type}</span></span></span>)}
                      </div>
                    </li>
                    <div className="text-muted" >
                      <label>Remarks</label>
                      <p className="text_remarks" > <small> Minimum {inputData?.currencyAmount + " " + inputData?.minCurrency} is required in {inputData?.side === "BUY" ? "Seller's" : "Buyer's"} P2P Wallet </small></p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="payment_method_form" tabIndex="-1" aria-labelledby="payment_method_formLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-2 pb-5" >
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">Set my payment method</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body px-md-4 custom-form">
              <form className="pay_radio pay_select">
                {type == "UPI" ? upiList?.map((item, index) => {
                  return (
                    <div key={item} className="form-check cursor-none"  >
                      <input type="radio" className="form-check-input text-gradient cursor-pointer" data-bs-dismiss="modal" id={item._id} value={item._id} name={item?._id}
                        checked={paymentMethod.some((paymentItem) => paymentItem._id === item._id)}
                        onChange={(e) => handleCheckboxChange(e, item)} />
                      <label className="form-check-label mb-0 cursor-pointer" data-bs-dismiss="modal" for={item._id} > {item?.upi_id} </label>
                    </div>
                  )
                })
                  : bankList?.map((item, index) => {
                    return (
                      <div key={item} className="form-check cursor-none"  >
                        <input type="radio" className="form-check-input text-gradient cursor-pointer" data-bs-dismiss="modal" value={item._id} name={item?._id} id={item._id}
                          checked={paymentMethod.some((paymentItem) => paymentItem._id == item._id)}
                          onChange={(e) => handleCheckboxChange(e, item)} />
                        <label for={item._id} className="form-check-label mb-0 sc_bnk_radio cursor-pointer" data-bs-dismiss="modal">
                          <b>
                            {item?.bank_name}
                          </b>
                          <small>{item?.account_number} </small>
                        </label>
                      </div>
                    )
                  })}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered"  >
          <div className="modal-content p-2 pb-5"  >
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl"> Choose Payment Method </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body py-0">
              {(upiList.length > 0 && bankList.length == 0) &&
                <button type="button" className="btn btn-gradient  px-3 btn-medium justify-content-between w-100 m-2 pe-0 "
                  data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setType("UPI"); }}>
                  <span>Select UPI </span> <i class="ri-arrow-right-line"></i></button>
              }
              {(upiList.length == 0 && bankList.length > 0) &&
                <button type="button" className="btn btn-gradient  px-3 btn-medium justify-content-between w-100 m-2 pe-0"
                  data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setType("BANK"); }}>
                  <span>  Bank Account </span><i class="ri-arrow-right-line"></i></button>
              }

              {(upiList.length > 0 && bankList.length > 0) &&
                paymentList?.map((item, index) => {
                  return (
                    <>
                      <button key={index} type="button" className="btn btn-gradient btn-medium px-3 justify-content-between w-100 my-2"
                        data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form"
                        onClick={() => { setType(item) }}><span>Selet {item} </span> <i class="ri-arrow-right-line pe-0"></i>
                      </button>
                    </>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPost;

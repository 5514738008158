import React, { useState, useEffect, useContext } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponents/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../../context/ProfileProvider";
const TwofactorPage = (props) => {
  const { handleUserDetails } = useContext(ProfileContext);

  const [checkedAuth, setCheckedAuth] = useState(props?.userDetails['2fa']);
  const [vCode, setVcode] = useState("");
  const [googleQr, setGoogleQr] = useState({});
  const [googleCode, setGoogleCode] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailID, setEmailId] = useState("");


  const checkType = () => {
    setVcode('')
    if (checkedAuth === 2) {
      getGoogleQr();
    } else if (checkedAuth === 3) {
      handleGetOtp(mobileNumber)
    }
    else if (checkedAuth === 1) {
      handleGetOtp(emailID)
    }
    else if (checkedAuth === 0) {
      if (props?.userDetails['2fa'] === 1) {
        handleGetOtp(emailID)
      }
      else if (props?.userDetails['2fa'] === 3) {
        handleGetOtp(mobileNumber)
      } else if (props?.userDetails['2fa'] === 2) {
        handleGetOtp(emailID)
      }
    }
  };

  const handleGetOtp = async (checkedAuth, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(checkedAuth, resend).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          $("#otp").modal('show');
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };


  const Update2Fa = async (checkedAuth, vCode, signId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.update2fa(checkedAuth, vCode, signId).then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          $("#google_modal").modal('hide');
          $("#otp").modal('hide');
          handleUserDetails();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  }


  const getGoogleQr = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.googleAuth().then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setGoogleQr(result.data.qr_code);
          setGoogleCode(result.data.secret.base32)
          $("#google_modal").modal('show');
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  function hideStep() {
    const pop = document.querySelector(".step_1");
    pop.classList.add("d-none");
    const pop2 = document.querySelector(".step_2");
    pop2.classList.remove("d-none");
  };


  const copyCode = () => {
    navigator.clipboard
      .writeText(googleCode)
      .then(() => {
        alertSuccessMessage("Copied!!");
      })
      .catch(() => {
        alertErrorMessage("Something went wrong");
      });
  };

  console.log(checkedAuth, "111111111")

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCheckedAuth(props?.userDetails['2fa'])
    setMobileNumber(props?.userDetails?.mobileNumber)
    setEmailId(props?.userDetails?.emailId)
  }, []);


  return (
    <>
      <div className="tab-pane" id="AuthencationPill" role="tabpanel" aria-labelledby="Authencation-pill">
        <div className="row">
          <div className="col-md-12 col-lg-10 m-auto ">
            <div className="card twofa_card">

              <div className="card-body" >

                <div className="card-header" >
                  <h3>2FA Security</h3>
                  <p className="mb-0 text-muted" >Setup 2FA for more security</p>
                </div>
                <div className="card-body_inner mt-4 mt-md-5" >
                  <p className="mb-0 text-muted" >Select Two Factor Authentication for your security.</p>
                  <hr />
                  <div className=" twofa_list d-flex align-items-center justify-space-between">
                    <h6 className="mb-0 w-100">Authenticator App</h6>
                    <div className="form-check  switch_btns form-switch">
                      <input className="form-check-input" type="checkbox" name="Two-Factor" id="1" onClick={() => setCheckedAuth(2)} checked={checkedAuth === 2} />
                    </div>
                  </div>
                  <div className="twofa_list d-flex align-items-center justify-space-between">
                    <h6 className="mb-0 w-100">Email OTP </h6>
                    <div className="form-check  switch_btns form-switch">
                      <input className="form-check-input" type="checkbox" name="Two-Factor" id="1" onChange={() => setCheckedAuth(1)} checked={checkedAuth === 1} disabled={!emailID} />
                    </div>
                  </div>
                  <div className="twofa_list d-flex align-items-center justify-space-between">
                    <h6 className="mb-0 w-100">Mobile OTP </h6>
                    <div className="form-check  switch_btns form-switch">
                      <input className="form-check-input" type="checkbox" name="Two-Factor" id="4" onChange={() => setCheckedAuth(3)} checked={checkedAuth === 3} disabled={!mobileNumber} />
                    </div>
                  </div>
                  <div className="twofa_list d-flex align-items-center justify-space-between">
                    <h6 className="mb-0 w-100">None</h6>
                    <div className="form-check  switch_btns form-switch">
                      <input className="form-check-input" type="checkbox" name="Two-Factor" id="3" onChange={() => setCheckedAuth(0)} checked={checkedAuth === 0} />
                    </div>
                  </div>
                </div>
                <hr />


                <div className="col-md-12 ">
                  <div className="field-box text-end pt-2">
                    <button className="btn  custom-btn px-4 px-5 btn-xl  justify-content-center btn-medium mb-0" type="button" data-toggle="modal" onClick={() => checkType()}><span>Save Settings</span></button>
                  </div>
                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="google_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl"> Authenticator App </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body conin_purchase">
              <div className="step_1 " >
                <div className="col-md-12 m-auto mb-2 text-center" >
                  <img className="img-fluid qr_img w-50" src={googleQr} />
                  <div className="field-box field-otp-box mb-2">
                    <input readOnly name="text" className="form-control cursor-pointer" type="text" value={googleCode} onClick={() => copyCode()} />
                  </div>
                  <small className="d-block text-center w-100 mb-4" >Click to copy code</small>
                  <button type="button" className="text-center next_btn btn-gradient m-auto w-100 btn btn-block" onClick={() => hideStep()}> Next </button>
                </div>
              </div>
              <div className="step_2 d-none" >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <div className="pt-5 mb-3" >
                    <input type="text" className="mb-3 form-control" value={vCode} placeholder="Enter Code.." onChange={(event) => setVcode(event.target.value)} />
                  </div>
                  <button
                    type="button"
                    className="text-center next_btn btn-gradient m-auto w-100 btn btn-block"
                    onClick={() => Update2Fa(
                      checkedAuth,
                      vCode,
                      checkedAuth === 0 ? emailID : checkedAuth === 1 ? emailID : checkedAuth === 2 ? emailID : mobileNumber

                    )}
                  >
                    <span>Submit</span>
                  </button>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="modal fade" id="otp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">Verify {(checkedAuth == 3 && checkedAuth == 1) ? 'Mobile' : (checkedAuth == 1 && checkedAuth == 3) ? 'Email' : (checkedAuth == 0 && checkedAuth == 1) ? 'Email' : (checkedAuth == 0 && checkedAuth == 3) ? 'Mobile' : ''}  OTP</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body conin_purchase">
              <div className="step_2 " >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <div className="pt-5" >
                    <input type="text" className="mb-3 form-control " placeholder="Enter OTP" value={vCode} onChange={(e) => { setVcode(e.target.value) }} />
                  </div>
                  <button type="button" onClick={() => Update2Fa(checkedAuth, vCode, checkedAuth === 0 ? emailID : checkedAuth === 1 ? emailID : checkedAuth === 2 ? emailID : mobileNumber)} disabled={!vCode} className="next_btn btn-gradient m-auto w-100 btn btn-block mt-3"> <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default TwofactorPage;

import { Link } from "react-router-dom"

const Launchpad = () =>{
    return(
        <>

<section className="  section-padding login_bg  login_sec ">
      <div className="section-overlay"></div>
      <section className="inner-page-banner pb-2">
        <div className="container">
          <div className="page_title">
            <h2 className="title">Launchpad </h2>
          </div>
        </div>
 

      </section> 



      <section className=" launchpad_sec live_prices mt-0 market_prices market_update_sec market_update_table ">
        <div className="container">

        <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                 
                <div className="ant-card-body">
                    <div className="row gx-4 " >
                        <div className="col-lg-3" >
                            <div className="lp_left" >
                                <span className="badge badge-primary" > Launchpad </span>

                                <div className="css-13to1co">
                                    <img alt="NEXB" src="images/nexb_coin.png" width="40" className="img-fluid" />
                                    NEXB Coin
                                </div> 

                                <div className="css-leoum0">
                                    <div className="css-13f9r66">
                                        <div data-bn-type="text" className="css-vurnku">Total Supply</div>
                                        <div data-bn-type="text" className="css-153t1uw"> 21 Million </div>
                                    </div>
                                    <div className="css-13f9r66">
                                        <div data-bn-type="text" className="css-vurnku">IEO </div>
                                        <div data-bn-type="text" className="css-153t1uw"> 1 Million </div>
                                    </div>
                                    <div className="css-13f9r66">
                                        <div data-bn-type="text" className="css-vurnku"> Price </div>
                                        <div data-bn-type="text" className="css-153t1uw"> 1 USDT / 1 NEXB </div> 
                                    </div> 
                                </div>

                                <div className="css-1fr1brr">
                                        <div data-bn-type="text" className="css-fj1yy5">  <small> Token Name </small>  <strong>NEXB</strong></div>
                                    </div>

                               




                            </div>
                        </div>


                        <div className="col-lg-9" >
                            
                            <div className="row align-content-center " >

                            <div className="col-lg-4" >
                                    <div className="l_cards" >
                                        <div className="l_card_header" >
                                            <div className="css-13to1co mt-2"> 
                                                NEXB Coin
                                            </div> 
                                            <div className="sb_now" >
                                                Swap NEXB Coin Now
                                            </div>

                                            <div className="sb_img" >
                                                <img alt="NEXB" src="images/nexb_coin.png" width="80" className="img-fluid" />
                                            </div>
                                            
                                            <Link to="/SwapLaunchpad" disabled className="btn btn-gradient btn-block w-100" >
                                                Swap NEXB
                                            </Link>
                                            
        


                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>


                        
                    </div>
                </div>

                
        </div>
        </div>
        </section>










      
      </section>
        
        </>
    )
}

export default Launchpad
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { validateEmail } from "../../../utils/Validation";


const ForgotPassword = () => {
    const navigate = useNavigate();
    const [inputData, setinputData] = useState({ otp: '', password: '', signId: '' });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleForgetPass = async (inputData) => {
        await AuthService.forgotPassword(inputData).then(async result => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    setinputData({ otp: '', password: '', signId: '' })
                    navigate("/login");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result?.message);
            }
        });
    };
    const handleGetOtp = async (signId, type) => {
        LoaderHelper.loaderStatus(true);
        await AuthService?.getOtp(signId, type).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    }
    return (
        <section className="  section-padding feature_bg login_sec pc_bg ">
            <div className="section-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 mx-auto">
                        <div className="page_title" >
                            <h3 className="d-block text-center mb-5" >  Forgot Password</h3>
                        </div>
                        <form className="custom-form    ticket-form mb-5 mb-lg-0" action="#" method="post" role="form">
                            <div className="card-header login-header" >
                                <ul className="nav nav-pills mb-3 login-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-Email-tab" data-bs-toggle="pill" data-bs-target="#pills-Email"
                                            type="button" role="tab" aria-controls="pills-Email" aria-selected="true" onClick={() => { setinputData({ otp: '', password: '', signId: '' }) }}>
                                            <img src="/images/login_email_icon.svg" className="me-3 show_dark" width="30" height="30" />
                                            <img alt="" src="/images/login_email_icon_light.svg" className="me-3 show_light" width="30" height="30"></img>
                                            <span>Email</span>
                                        </button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-Phone-tab" data-bs-toggle="pill" data-bs-target="#pills-Phone" type="button" role="tab" aria-controls="pills-Phone" aria-selected="false" onClick={() => { setinputData({ otp: '', password: '', signId: '' }) }}>
                                            <img src="/images/login_mobile_icon.svg" className="me-3 show_dark" width="30" height="30" />
                                            <img alt="" src="/images/login_mobile_icon_light.svg" className="me-3 show_light" width="30" height="30"></img>
                                            <span>Phone</span>
                                        </button>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane show active" id="pills-Email" role="tabpanel" aria-labelledby="pills-Email-tab">
                                    <div className="card-body " >
                                        <div className="row">
                                            <div className="col-md-12" >
                                                <label className="  mb-1"> Email Address <em className="text-danger" > *</em></label>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-12">
                                                <input type="email" name="signId" id="form-phone" className="form-control email_input" placeholder="Enter Email" onChange={handleInputChange} value={inputData?.signId} />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <label className="  mb-1">Email Verification Code <em className="text-danger" > *</em></label>
                                                <div className=" field-otp-box" >
                                                    <input type="text" name="otp" id="form-otp" className="form-control" placeholder="Enter  Code" onChange={handleInputChange} value={inputData?.otp} />
                                                    <button type="button" className="btn btn-xs  custom-btn" onClick={() => validateEmail(inputData?.signId) ? alertErrorMessage("Invalid Email") : handleGetOtp(inputData?.signId, 'forgot')}><span> GET OTP </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <label className="  mb-1" > New Password<em className="text-danger" > *</em></label>
                                                <input type="text" name="password" id="form-email" className="form-control" placeholder="New Password" required onChange={handleInputChange} value={inputData?.password} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-10 col-12 mx-auto mt-4">
                                            <button type="button" className="btn custom-btn w-100 btn-block btn-xl" onClick={() => { handleForgetPass(inputData) }}> Forgot Password </button>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                                            <p> Back to  <Link className="fw-bold" to="/login"> Login? </Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="pills-Phone" role="tabpanel" aria-labelledby="pills-Phone-tab">
                                    <div className="card-body " >
                                        <div className="row">
                                            <div className="col-md-12" >
                                                <label className="  mb-1"> Mobile Number <em className="text-danger" > *</em></label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <input type="number" name="signId" id="form-phone" pattern="0000-000-000" className="form-control" placeholder="Enter Mobile Number" required onChange={handleInputChange} onWheelCapture={e => {
                                                    e.target.blur()
                                                }} value={inputData?.signId} />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <label className="  mb-1"> Phone Verification Code <em className="text-danger" > *</em></label>
                                                <div className=" field-otp-box" >
                                                    <input type="text" name="otp" id="form-otp" className="form-control" placeholder="Enter  Code" onChange={handleInputChange} value={inputData?.otp} />
                                                    <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(inputData?.signId, 'forgot') }}><span> GET OTP </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <label className="  mb-1" > New Password<em className="text-danger" > *</em></label>
                                                <input type="text" name="password" id="form-email" className="form-control" placeholder="New Password" required onChange={handleInputChange} value={inputData?.password} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-10 col-12 mx-auto mt-4">
                                            <button type="button" className="btn custom-btn w-100 btn-block btn-xl" onClick={() => { handleForgetPass(inputData) }}> Forgot Password </button>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                                            <p> Back to  <Link className="fw-bold" to="/login"> Login? </Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword



import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const ComingSoonPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <section className="  section-padding pc_bg  login_sec">
      <div className="section-overlay"></div>

      <div className="container">

        <div className="coming_soon" >
          <img src="/images/coming_soon.svg" className="img-fluid mb-5 show_light" />
          <img src="/images/coming_soon_dark.svg" className="img-fluid mb-5  show_dark" />
          <Link to="/" className="btn custom-btn btn-xl  " > <i className="ri-home-7-line me-3"></i> Back to Home</Link>
        </div>

      </div>
    </section>
  )
}

export default ComingSoonPage

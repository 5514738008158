import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { Helmet } from "react-helmet-async";

const ReferalPage = () => {


  const [refferlsData, setRefferalData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const handleReffrals = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.refferlsList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setRefferalData(result?.data);
      }
    });
  };

  function copyToClipboard(copyText) {
    navigator.clipboard.writeText(`https://cvtrade.io/signup?reffcode=${copyText}`);
    setIsCopied(true);
  }

  function handleShareFacebook() {
    const shareText = `Check out my code: ${refferlsData?.user_code}!`;
    const shareUrl = `https://cvtrade.io//share?code=${encodeURIComponent(shareText)}`;
    const shareWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`,
      '_blank'
    );
    if (!shareWindow) {
      alert('Please disable your popup blocker to share the page.');
    }
  }

/*   function handleShareWhatsApp() {
    const shareUrl = `https://cvtrade.io/signup?reffcode=${refferlsData?.user_code}`;
    const shareLink = `https://web.whatsapp.com/send?text=${shareUrl}`;
    const shareWindow = window.open(shareLink, '_blank');
    if (!shareWindow) {
      alert('Please disable your popup blocker to share the page.');
    }
  } */

  function handleShareWhatsApp() {
    const shareUrl = `https://cvtrade.io/signup?reffcode=${refferlsData?.user_code}`;
    const message = encodeURIComponent(shareUrl);
    let shareLink;
  
    if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      shareLink = `whatsapp://send?text=${message}`;
    } else {
      shareLink = `https://web.whatsapp.com/send?text=${message}`;
    }
  
    const shareWindow = window.open(shareLink, '_blank');
    if (!shareWindow) {
      alert('Please disable your popup blocker to share the page.');
    }
  }

  function handleShareTwitter() {
    const shareText = `Check out my code: ${refferlsData?.user_code}!`;
    const shareUrl = `https://cvtrade.io/signup?reffcode=${refferlsData?.user_code}`;
    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: shareText,
        url: shareUrl,
      });
    } else {
      const shareWindow = window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          shareText
        )}&url=${encodeURIComponent(shareUrl)}`,
        "_blank"
      );
      if (!shareWindow) {
        alert("Please disable your popup blocker to share the page.");
      }
    }
  }

  function handleShareTelegram() {
    const shareUrl = `https://cvtrade.io/signup?reffcode=${refferlsData?.user_code}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`;
    window.open(telegramUrl, '_blank');
  }

  useEffect(() => {
    handleReffrals();
  }, []);

  return (
    <>
    <Helmet>
      <title> CV Trade | The world class new generation crypto asset exchange</title>
    </Helmet>
    <section className="pb-5 mt-3 mt-md-5">
      <div className="container">
        <div className="card twofa_card">
          <div className="card-body" >
            <div className="row gx-5">
              <div className="col-lg-6">
                <div className="ref_col" >
                  <div className="ref_cards mb-0" >
                    <p className="mb-0" >Total Referral Comission</p>
                    <h3 className="mb-0" >10,000 SHIB</h3>
                  </div>
                  <div className="card-header ref_header  ">
                    <h2 className="ref_title" >
                      <b className="text-success" >Earn 5000 SHIB</b>    for each friend you refer!
                    </h2>
                    <h6>Invite a friend to CV trade and Earn 5000 SHIB after completing their KYC.</h6>
                  </div>
                  <div className="card-body_inner p-0" >
                    <div className=" ref_body">
                      {/* <div className="referrals__title">Invite friends & Earn <span className="text-gradient text-underline" >5000</span> SHIBA INU Free  </div> */}
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="field">
                            <div className="field__label">Referral code</div>
                            <div className="field__wrap  field-otp-box">
                              <input className="field__input form-control" type="text" name="referral-code" defaultValue={refferlsData?.user_code} readOnly />
                              <button type="button" className="btn btn-xs custom-btn" onClick={() => copyToClipboard(refferlsData?.user_code)}><span> {isCopied ? 'Copied!' : 'Copy'} </span></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <label>
                        <small>OR share via</small></label>
                      <div className="d-flex  joc_social_row mt-2">
                        <span className="joc_social cursor-pointer" title="Facebook" onClick={handleShareFacebook}>
                          <i className="ri-facebook-line  ri-xl"></i>
                        </span>
                        <span className="joc_social cursor-pointer" title="Whatsapp" onClick={handleShareWhatsApp}>
                          <i className="ri-whatsapp-fill  ri-xl"></i>
                        </span>
                        <span className="joc_social cursor-pointer" title="Twitter" onClick={handleShareTwitter}>
                          <i className="ri-twitter-line  ri-xl"></i>
                        </span>
                        <span className="joc_social cursor-pointer" title="Instagram" onClick={handleShareTelegram}>
                          <i className="ri-telegram-fill  ri-xl"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ref_img p-3" >
                  <img src="/images/referal.svg" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default ReferalPage;
